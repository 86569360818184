import Vue from 'vue'
import Vuex from 'vuex'

import stateCommon from "./stateCommon"
import stateIO from "./stateIO"
import stateNotice from "./stateNotice"

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    ...stateCommon,
    ...stateIO,
    ...stateNotice,
    showOldPrivate: Date.now() < 1707663601000,
  },
  mutations: {
  },
  actions: {
  },
  modules: {
  }
})
