// 컴퓨티드 네임 가이드
// gc_form_${name}() {}

export default {
  // 이메일 도메인 리스트
  gc_form_emailList() {
    const text = this.gc_langText.common.account.directInput[this.gc_lang]

    return [
      'naver.com',
      'daum.net',
      'gmail.com',
      'nate.com',
      {
        text,
        value: null
      }
    ]
  }
}