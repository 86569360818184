// 메소드 네임 가이드
// api_${name}() {}

import env from "../plugins/process.env"
import axios from "../plugins/axios"

export default {
  // 공지사항
  api_getNotice() {
    const it = this
    const params = {
      page : it.paging.cur,
      limit: it.paging.limit
    }

    it.article = null

    return axios({
      method: 'GET',
      baseURL: env.API_BASE_URL,
      url: `/api/v1/notice`,
      params
    }).then( result => {
      const state = result.data.code

      if (state !== 200) { throw state }

      const paging = result.data.pagination
      const list = result.data.result

      it.paging.cur = paging.current_page // 현재 페이지
      it.paging.max = paging.count_pages // 총 페이지 수
      it.paging.limit = +paging.limit // 리밋 수치
      it.paging.total = paging.total_count // 컨텐츠 총 개수

      it.list = list
    }).catch(error => {
      it._api_error(`code: ${error}\nat: api_getNotice`)
    }).finally( () => {
      it.$store.state.pageLoaded = true
    })
  }
}