<template>
  <v-list-item class="mb-4 justify-center">
    <div class="d-inline mx-8 c-border">
      <v-btn
        :class="'px-0 subtitle-2' + (gc_isKoLang ? ' cyan' : '')"
        text
        :dark="gc_isKoLang"
        @click="gm_changeLang('ko')"
      >
        KO
      </v-btn>
      <v-btn
        :class="'px-0 subtitle-2' + (!gc_isKoLang ? ' cyan' : '')"
        text
        :dark="!gc_isKoLang"
        @click="gm_changeLang('en')"
      >
        EN
      </v-btn>
    </div>
  </v-list-item>
</template>

<script>
export default {
  name: "AppNavigationDrawerLang"
}
</script>

<style lang="sass" scoped>
.c-border
  border-radius: 4px
  border: 1px solid #000000
</style>