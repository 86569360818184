<template>
  <v-simple-table class="rounded-0">
    <template v-slot:default>
      <thead>
      <tr>
        <th class="text-center">
          DATE
        </th>
        <th class="text-center">
          AGENDA
        </th>
        <th class="text-center">
          SPEAKER
        </th>
        <th class="text-center">
          PRODUCT
        </th>
        <th class="text-center">
          STATUS
        </th>
      </tr>
      </thead>
      <tbody class="c-blue-line">
      <tr v-if="items.length === 0">
        <td
          class="text-center"
          colspan="5"
        >
          {{ gc_langText.home.text.noEvent[gc_lang] }}
        </td>
      </tr>
      <tr
        v-else
        v-for="(item, i) in items"
        :key="'event-list-' + i"
      >
        <td class="text-center c-ws-pre">{{ item.begin_at.replace(/\s.*/, '').replace(/-/, '\n') }}</td>
        <td class="text-center">
          {{ item[`name${gc_lang_prefix}`] }}
        </td>
        <td class="text-center c-wb-keep-all">
          {{ item[`speaker${gc_lang_prefix}`] }}
          <br v-if="item[`speaker${gc_lang_prefix}`] && item[`location${gc_lang_prefix}`]">
          <span
            v-if="item[`location${gc_lang_prefix}`]"
            class="c-fs-12"
          >{{ item[`location${gc_lang_prefix}`] }}</span>
        </td>
        <td class="text-center">
          {{ item.category ? item.category[`name${gc_lang_prefix}`] : '' }}
        </td>
        <td>

          <!--  종료된 강의: 재시청  -->
          <v-btn
            v-if="item.status === 2 || item.status === undefined"
            class="c-tt-none"
            color="cyan"
            elevation="0"
            :to="{ name: 'Replay', query: { id: item.id } }"
            dark
            block
            small
            tile
          >
            {{ gc_langText.home.btn.ended[gc_lang] }}
          </v-btn>

          <!--  사전 등록 버튼  -->
          <v-btn
            v-else-if="item.is_attend === 0"
            class="c-tt-none"
            color="cyan"
            elevation="0"
            @click="$emit('streamLiveOpen', item)"
            dark
            block
            small
            tile
          >
            {{ gc_langText.home.btn.preRegistration[gc_lang] }}
          </v-btn>

          <template v-else>
            <!--
              이곳에서는 is_attend === 1 일 경우를 의미하므로
              태그 내 조건문에 해당 조건 포함시키지 않음
            -->

            <!--  시청 버튼  -->
            <v-btn
              v-if="item.status === 1"
              class="c-tt-none"
              color="cyan"
              elevation="0"
              @click="$emit('streamLiveOpen', item)"
              dark
              block
              small
              tile
            >
              {{ gc_langText.home.btn.watch[gc_lang] }}
            </v-btn>

            <!--  사전등록완료  -->
            <v-btn
              v-else-if="item.status === 0"
              class="c-tt-none"
              color="grey"
              elevation="0"
              @click="$emit('streamLiveOpen', item)"
              block
              small
              tile
            >
              {{ gc_langText.home.btn.registered[gc_lang] }}
            </v-btn>

          </template>

        </td>
      </tr>
      </tbody>
    </template>
  </v-simple-table>
</template>

<script>
export default {
  name: "TableList",

  props: {
    items: {
      type: Array,
      default: () => ([])
    }
  }
}
</script>

<style lang="sass" scoped>
th, td
  padding: 0 4px !important
.c-fs-12
  font-size: 12px !important
</style>