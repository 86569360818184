<template>
  <router-link
    class="d-flex align-self-stretch px-3"
    to="/"
  >
    <v-img
      class="align-self-center"
      :src="require('../../assets/logo.svg')"
      :width="width"
      contain
    />
  </router-link>
</template>

<script>
export default {
  name: "AppBarLogo",

  computed: {
    width() {
      const bp = this.gc_bp

      return bp.md ? 214 :
        bp.sm ? 143 :
          bp.width < 420 ? 214 : 286
    }
  }
}
</script>

<style lang="sass" scoped>

</style>