// 메소드 네임 가이드
// api_${name}() {}

import env from "../plugins/process.env"
import axios from "../plugins/axios"

export default {
  // 인증번호 요청
  api_sendInspectNumber() {
    // 인증 코드 전송 버튼이 비활성화 일 경우 리턴처리
    if (this.sendDisabled) { return }

    const it = this
    const phone = this.form.phone
    const params = { phone }

    const phoneAuth = this.phoneAuth

    phoneAuth.sendRequesting = true
    phoneAuth.sent = true
    phoneAuth.validSeconds = 0

    return axios({
      method: 'POST',
      baseURL: env.API_BASE_URL,
      url: `/api/v1/auth/send_phone_inspect_sms`,
      params
    }).then( result => {
      const state = result.data.code

      if ( state === 412 ) {
        const lang = it.gc_lang
        const text = `${it.gc_langText.join.snsSendError[lang]}\ncode: ${state}`

        it.gm_alertOpen({
          text,
        })
      }
      else if ( state !== 200 ) { throw state }
    }).catch(error => {
      it._api_error(`code: ${error}\nat: api_sendInspectNumber`)
    }).finally( () => {

      // 이곳에서 ======================================================
      // 원래 then 내부 조건문 다음에 오는 로직이지만
      // 요청에 의해 애러가 발생하더라도 인증번호 입력 란 활성화를 위해 이곳으로 옮김
      phoneAuth.resendSeconds = phoneAuth.resendSecondsDefault
      phoneAuth.validSeconds = 180 // 리스폰 받은 유효시간 (초)
      it.intervalResend()
      it.intervalAuth()
      it.$refs.authNum.$refs.input.focus()
      // ====================================================== 여기까지

      phoneAuth.sendRequesting = false
    })
  },

  // 인증번호 인증확인 요청
  api_checkInspectNumber() {
    // 인증 코드 확인 버튼이 비활성화 일 경우 리턴처리
    if (this.authDisabled) { return }

    const it = this
    const phone = this.form.phone
    const token = this.phoneAuth.inputCode
    const params = {
      phone,
      token
    }

    const phoneAuth = this.phoneAuth
    const lang = this.gc_lang
    const text = this.gc_langText.common.valid.phoneAuthWrong[lang]

    phoneAuth.validRequesting = true

    return axios({
      method: 'POST',
      baseURL: env.API_BASE_URL,
      url: `/api/v1/auth/inspect_sms_code`,
      params
    }).then( result => {
      const state = result.data.code

      // 성공적으로 번호를 인증한 경우
      if ( state === 200 ) {
        it.form.phoneAuth = true
        phoneAuth.validWrongMsg = ''
      } else {
        phoneAuth.validWrongMsg = text
      }
    }).catch(error => {
      it._api_error(`code: ${error}\nat: api_checkInspectNumber`)
    }).finally( () => {
      phoneAuth.validRequesting = false
    })
  }
}