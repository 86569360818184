<template>
  <v-list-item class="mb-16">
    <!--  AppBarRight.vue 와 같다고 볼 수 있지만
      모바일 뷰에서 세부적인 디테일을 주기 위해 별도로 가지고 간다
      ㅠㅠ 내 능력 한계인가... 할일이 넘 많아서 넘기는건가... ㅠㅠ  -->

    <!--  좌측 매뉴  -->
    <v-col
      class="pl-0 pr-5 py-0 text-right c-divider"
      cols="6"
    >
      <v-btn
        v-if="$store.state.user"
        class="pa-0 c-wh-auto"
        to='/logout'
        plain
      >
        {{ gc_langText.common.account.signOut[gc_lang] }}
      </v-btn>
      <v-btn
        v-else
        class="pa-0 c-wh-auto"
        to="/login"
        plain
      >
        {{ gc_langText.common.account.signIn[gc_lang] }}
      </v-btn>
    </v-col>

    <!--  우측 매뉴  -->
    <v-col
      class="pl-5 pr-0 py-0 text-left"
      cols="6"
    >
      <v-btn
        v-if="$store.state.user"
        class="pa-0 c-wh-auto"
        :to="app_accountLink"
        plain
      >
        {{ gc_langText.app.account.options[gc_lang] }}
      </v-btn>
      <v-btn
        v-else
        class="pa-0 c-wh-auto"
        to="/join"
        plain
      >
        {{ gc_langText.app.account.join[gc_lang] }}
      </v-btn>
    </v-col>

  </v-list-item>
</template>

<script>
export default {
  name: "AppNavigationDrawerAccount"
}
</script>

<style lang="sass" scoped>
.c-wh-auto
  min-width: auto !important
  height: auto !important
  font-size: 1.125rem
</style>