<template>
  <v-navigation-drawer
    v-if="gc_bp.xs"
    v-model="$store.state.drawer"
    :width="gc_bp.width"
    app
  >
    <template v-slot:prepend>
      <v-list-item>
        <v-list-item-title class="d-flex c-h-52">

          <app-bar-logo/>

          <v-spacer/>

          <v-btn
            icon
            x-large
            @click="$store.state.drawer = !$store.state.drawer"
          >
            <v-icon x-large>mdi-close</v-icon>
          </v-btn>

        </v-list-item-title>
      </v-list-item>
    </template>

    <v-divider/>

    <v-list
      class="mt-8"
      dense
    >

      <!--  페이지: 메인  -->
      <v-list-item class="px-8">
        <v-btn
          class="text-h5 font-weight-bold justify-start"
          active-class="cyan--text"
          to="/"
          block
          plain
        >
          {{ gc_langText.app.nav.home[gc_lang] }}
        </v-btn>
      </v-list-item>

      <!--  페이지: 스트리밍  -->
      <v-list-item class="px-8">
        <v-badge
          :color="app_isActiveLiveNavBadge ? 'red' : 'grey darken-1'"
          content="On Air"
          :offset-y="16"
          bordered
          overlap
        >
          <v-btn
            class="text-h5 font-weight-bold justify-start"
            active-class="cyan--text"
            @click="gm_clickNavStream"
            :input-value="app_isActiveLiveNav"
            block
            plain
          >
            {{ gc_langText.app.nav.live[gc_lang] }}
          </v-btn>
        </v-badge>
      </v-list-item>

      <!--  페이지: 다시보기  -->
      <v-list-item class="px-8">
        <v-btn
          class="text-h5 font-weight-bold justify-start"
          active-class="cyan--text"
          to="/replay"
          block
          plain
        >
          {{ gc_langText.app.nav.replay[gc_lang] }}
        </v-btn>
      </v-list-item>

      <!--  페이지: 공지사항  -->
      <v-list-item class="px-8">
        <v-btn
          class="text-h5 font-weight-bold justify-start"
          active-class="cyan--text"
          to="/notice"
          block
          plain
        >
          {{ gc_langText.app.nav.notice[gc_lang] }}
        </v-btn>
      </v-list-item>

      <!--  페이지: 알파애듀 링크  -->
      <v-list-item class="px-8">
        <v-btn
          v-if="gc_user && gc_user.type === 2"
          class="text-h5 font-weight-bold justify-start"
          active-class="cyan--text"
          @click="gm_goToSso"
          block
          plain
        >
          {{ gc_SSOText }}
        </v-btn>
      </v-list-item>

    </v-list>

    <template v-slot:append>
      <v-list class="pb-16">

        <app-navigation-drawer-lang/>

        <app-navigation-drawer-account/>

      </v-list>
    </template>

  </v-navigation-drawer>
</template>

<script>
import AppBarLogo from "./AppBarLogo"
import AppNavigationDrawerLang from "./AppNavigationDrawerLang"
import AppNavigationDrawerAccount from "./AppNavigationDrawerAccount"

export default {
  name: "AppNavigationDrawer",

  components: {
    AppBarLogo,
    AppNavigationDrawerLang,
    AppNavigationDrawerAccount
  }
}
</script>

<style lang="sass" scoped>
.c-h-52
  height: 52px
</style>