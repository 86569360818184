// 메소드 네임 가이드
// gm_form_${name}() {}

export default {
  // 메일 도메인 셀렉트 시
  gm_form_selectMail(val) {
    const el = this.$refs.mailBack.$refs.input

    this.form.mailBack = val
    !val && setTimeout( () => el.focus() )
  },

  // 휴대폰 입력란 숫자 이외 입력시 강제 삭제
  gm_form_onlyNumber() {
    const form = this.form

    // 새로운 입력이 감지되면 인증받은 플레그 초기화
    if (typeof(form.phoneAuth) === 'boolean') {
      form.phoneAuth = false
    }

    setTimeout(() => form.phone = form.phone.replaceAll(/[^0-9]/g, '') )
  },

  // 매 타이핑 시마다 비밀번호 동일여부 채크
  gm_form_isPwEqual() {
    const equal = this.form.pw === this.form.pwr
    const length6 = this.form.pw.length >= 6 || this.form.pwr.length >= 6

    equal && length6 && this.$refs.pwForm.resetValidation()
  },
}