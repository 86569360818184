<template>
  <v-tabs
    background-color="transparent"
    :mobile-breakpoint="700"
    color="cyan"
    hide-slider
    fixed-tabs
    centered
  >
    <!--  페이지: 메인  -->
    <v-tab
      :class="tabClass"
      to="/"
    >
      {{ gc_langText.app.nav.home[gc_lang] }}
    </v-tab>

    <!--  페이지: 스트리밍  -->
    <v-tab
      :class="tabClass"
      @click="gm_clickNavStream"
    >
      <v-badge
        :color="app_isActiveLiveNavBadge ? 'red' : 'grey darken-1'"
        content="On Air"
        :dot="gc_bp.sm"
        bordered
      >
        {{ gc_langText.app.nav.live[gc_lang] }}
      </v-badge>
    </v-tab>

    <!--  페이지: 다시보기  -->
    <v-tab
      :class="tabClass"
      to="/replay"
    >
      {{ gc_langText.app.nav.replay[gc_lang] }}
    </v-tab>

    <!--  페이지: 공지사항  -->
    <v-tab
      :class="tabClass"
      to="/notice"
      @click="refresh"
    >
      {{ gc_langText.app.nav.notice[gc_lang] }}
    </v-tab>

    <!--  페이지: SSO 링크  -->
    <v-tab
      v-if="gc_user && gc_user.type === 2"
      :class="tabClass"
      @click="gm_goToSso"
    >
      {{ gc_SSOText }}
    </v-tab>
  </v-tabs>
</template>

<script>
export default {
  name: "AppBarTabs",

  computed: {

    // 해상도에 따른 탭 아이템 텍스트 사이즈
    tabClass() {
      const fontSize = this.gc_bp.sm ? '' : ' text-h6'

      return 'pa-0 c-mw-auto' + fontSize
    }
  },

  methods: {
    // 페이지 새로고침
    refresh() {
      // 해당 탭 클릭하면 공지사항 리스트 1페이지로 이동시켜달라고 하는데
      // 다 만들어 놓으니까 바꿔달라고 해서 개떡같이 적용함
      this.$store.state.refreshNotice = !this.$store.state.refreshNotice
    }
  }
}
</script>

<style lang="sass" scoped>
.c-mw-auto
  min-width: auto
</style>