<template>
  <v-dialog
    v-model="show"
    transition="dialog-bottom-transition"
    :persistent="requestingAttend"
    scrollable
    fullscreen
  >

    <v-card tile>

      <!--  닫기 버튼  -->
      <v-card-actions>
        <v-spacer/>
        <v-btn
          class="c-tt-none"
          color="black"
          @click="show = false"
          :disabled="requestingAttend"
          text
        >
          <span class="mr-2">Close</span>
          <v-icon>mdi-close</v-icon>
        </v-btn>

      </v-card-actions>

      <!--
        기본 레이아웃 구조
        v-row ------- 컨테이너 역할
          v-col ----- 실질적 row 역할
            v-row --- col 레퍼 역할
              v-col - col
        추천되는 구조는 아니지만 wide 스타일의 row 내에 있는 컨텐츠를
        non-wide 스타일로 보여주기 위함임
      -->
      <v-row class="ma-0 align-content-start">

        <!--  row 1  -->
        <v-col
          class="py-0"
          cols="12"
          :style="`background-color: ${item[`background_color${gc_lang_prefix}`]}`"
        >
          <v-row class="my-0 c-layout">

            <!--  이미지  -->
            <v-col
              class="pa-0"
              cols="12"
            >
              <v-img
                class="white--text align-stretch c-d-flex"
                :src="item[`photo_1${gc_lang_prefix}`]"
                :height="carouselHeight"
              />
            </v-col>

          </v-row>
        </v-col>

        <!--  row 2: 버튼그룹  -->
        <v-col
          class="py-8 white"
          cols="12"
        >
          <v-row class="c-layout">
            <v-col
              v-if="item.status !== 2"
              class="mx-auto c-mx-w320"
              cols="12"
            >
              <!--  D-Day 카운터  -->
              <v-card-actions class="justify-center c-action-box c-bd-black">
                <v-chip
                  class="px-8 rounded-0 c-line-height-normal"
                  color="transparent"
                >
                <span
                  v-if="gm_home_dDay(item.begin_at) && item.status === 0"
                  class="d-flex align-center black--text"
                  v-html="gm_home_dDay(item.begin_at)"
                />
                  <span
                    v-else-if="item.status === 1"
                    class="d-flex align-center black--text"
                  >
                  <span class="c-d-day">D-Day</span>
                </span>
                </v-chip>
              </v-card-actions>
            </v-col>

            <v-col
              class="text-center"
              cols="12"
            >
              <template v-if="item.is_attend !== 0">

                <!--  종료된 강의  -->
                <v-btn
                  v-if="item.status === 2"
                  class="px-8 c-tt-none"
                  color="cyan"
                  elevation="0"
                  @click.stop=""
                  dark
                  x-large
                  tile
                >
                  {{ gc_langText.home.btn.ended[gc_lang] }}
                </v-btn>

                <!--  시청 버튼  -->
                <v-btn
                  v-else-if="item.status === 1"
                  class="px-16 c-tt-none"
                  color="cyan"
                  elevation="0"
                  min-width="128"
                  :to="`/stream/${item.id}`"
                  dark
                  x-large
                  tile
                >
                  {{ gc_langText.home.btn.watch[gc_lang] }}
                </v-btn>

                <p
                  v-if="item.status === 0"
                  class="mb-5"
                >
                  {{ gc_langText.home.text.pleaseWait[gc_lang] }}
                </p>

                <!--  사전등록완료  -->
                <v-chip
                  v-if="item.status === 0"
                  class="px-16 rounded-0 c-line-height-normal"
                  color="cyan"
                  large
                  dark
                >
                  {{ gc_langText.home.btn.registered[gc_lang] }}
                </v-chip>

              </template>

              <!--  사전 등록 버튼  -->
              <v-btn
                v-else
                class="px-16 c-tt-none"
                color="cyan"
                elevation="0"
                min-width="128"
                @click="attend"
                :disabled="requestingAttend"
                :loading="requestingAttend"
                x-large
                dark
                tile
              >
                {{ gc_langText.home.btn.preRegistration[gc_lang] }}
              </v-btn>

            </v-col>
          </v-row>
        </v-col>

      </v-row>

    </v-card>

  </v-dialog>
</template>

<script>
export default {
  name: "RegisterDialog",

  props: {
    _show: {
      type: Boolean,
      default: false
    },
    _item: {
      type: Object,
      default: () => ({})
    },
    curTime: {
      type: Number,
      default: 0
    },
    updatedTick: {
      type: Boolean,
      default: false
    }
  },

  data: () => ({
    // 팝업 토글 여부
    show: false,

    // 사전 등록 요청 중
    requestingAttend: false,

    // 아이템 정보
    /*
    * _item 을 item 에 바인드 하여 사용하지만
    * 경우에 따라서 직접 데이터 업데이트를 하기 위해
    * 이 두 객체를 구분하여 둠
    */
    item: {}
  }),

  computed: {
    carouselHeight() {
      const base = this.gc_bp.width > 1240 ? 1240 : this.gc_bp.width

      return base / 2.48
    }
  },

  watch: {
    _show(val) {
      this.show = val
    },

    show(val) {
      !val && this.$emit('eventClose')
    },

    _item(val) {
      this.item = val
    },

    updatedTick() {
      this.requestingAttend = false
    }
  },

  methods: {
    // 사전 등록 신청
    attend() {
      /*
       * 로직이 조금 복잡하여 플로우 설명을 넣는다
       * 1. confirm 창 띄움
       * 2. confirm 승인시 api_attendEvent 메서드 호출
       * 3. api_attendEvent 실행 후 조건에 따라
       * 3-1. 이벤트 업데이트가 필요 시 $emit('updateEvents')
       * 3-2. 이벤트 업데이트가 필요 없을 시 종료
       * 4. $emit('updateEvents') 에 의해 api_getEventList 메서드 호출
       * 5. api_getEventList 실행 후 updatedTick 값 변경으로 업데이트 알림
       * 6. 알림 감지 시 UI/UX 로딩중(요청중) 활성화 값인 requestingAttend = false
       */
      const it = this
      const lang = this.gc_lang
      const text = this.gc_langText.common.alert.text.doRequestAttend

      this.gm_alertOpen({
        text: text[lang],
        confirm: true,
        callback: () => {
          it.requestingAttend = true
          it.api_attendEvent()
        }
      })
    }
  }
}
</script>

<style lang="sass" scoped>
.c-mx-w320
  max-width: 320px
</style>