// 메소드 네임 가이드
// api_${name}() {}

import env from "../plugins/process.env"
import axios from "../plugins/axios"

export default {
  // 카테고리 리스트 요청
  api_getReplayCategoryList() {
    const it = this

    return axios({
      method: 'GET',
      baseURL: env.API_BASE_URL,
      url: `/api/v1/category`
    }).then( result => {
      const list = result.data.result

      if ( Array.isArray(list) ) {
        it.tabs.list = list
      }
    }).finally( () => {
      it.tabs.responded = true
    })
  },

  // 아이템 리스트 요청
  api_getReplayItemList() {
    const it = this
    const params = {}
    const category_id = this.$route.query.category

    if (category_id) {
      params.category_id = category_id
    }

    return axios({
      method: 'GET',
      baseURL: env.API_BASE_URL,
      url: `/api/v1/vod`,
      params
    }).then( result => {
      const list = result.data.result

      if ( Array.isArray(list) ) {
        it.items.list = list

        // 로딩 완료 후 열어야 할 아이디가 있을 경우 유효성 검사 후 컨텐츠 팝업 오픈
        const id = Number( it.$route.query.id )
        const idx = list.findIndex( eve => eve.id === id )

        // 컨텐츠가 존재 할 경우 팝업
        if (id && idx > -1) {
          it.dialogOpen(list[idx], idx)
        }
      // 컨텐츠가 존재하지 않을 경우
        else if (id && idx === -1) {
          const lang = it.gc_lang
          const text = it.gc_langText.replay.text.noContents[lang]

          it.gm_alertOpen({
            text,
            callback: () => it.$router.push({ query: {} })
          })
        }
      }
    }).finally( () => {
      it.items.responded = true
      it.$store.state.pageLoaded = true
    })
  },

  // 단일 아이템 조회수 증가 (원래 기능은 단일 아이템 정보 조회임)
  api_updateViews() {
    const vod_id = this.$route.query.id

    return axios({
      method: 'GET',
      baseURL: env.API_BASE_URL,
      url: `/api/v1/vod/${vod_id}`,
    })
  },

  // vod 질문등록
  api_submitVodQuestion() {
    const it = this
    const params = {
      vod_id: it.item.id,
      contents: it.content
    }

    it.requesting = true

    return axios({
      method: 'POST',
      baseURL: env.API_BASE_URL,
      url: `/api/v1/vod_question`,
      params
    }).then( result => {
      const state = result.data.code
      const lang = this.gc_lang

      if (state !== 200) { throw state }

      const text = this.gc_langText.replay.text.registeredQuestion[lang]

      it.gm_alertOpen({
        text,
        callback: () => it.show = false
      })
    }).catch(error => {
      it._api_error(`code: ${error}\nat: api_submitVodQuestion`)
    }).finally( () => {
      it.requesting = false
    })
  },

  // 최종 시청 진행시각 업데이트
  api_updateWatchProgress( seconds ) {
    // 시청완료하지 않았고, 기존 최종 시청 진행 시각이 업데이트 시각보다 클 경우 종료
    if (this.item.view_complete || this.item.vod_location >= seconds) { return }

    // vod 객체 내에 최종시청시간 포인트는 API 응답여부에 관계없이 탐색시간을 증가
    this.$emit('updateHighWatchTime', seconds, this.itemIdx)

    const vod_id = this.item.id
    const params = {
      time: seconds
    }

    return axios({
      method: 'POST',
      baseURL: env.API_BASE_URL,
      url: `/api/v1/vod/${vod_id}/set_location`,
      params
    })
  },

  // 시청시간 추가
  api_vodUpdateWatchTime() {
    const vod_id = this.item.id
    const params = {
      time: Math.floor( this.watchTime.cumulative )
    }

    this.watchTime.cumulative = 0

    return axios({
      method: 'POST',
      baseURL: env.API_BASE_URL,
      url: `/api/v1/vod/${vod_id}/add_time`,
      params
    })
  },

  // vod 시청 완료
  api_vodWatchedDone() {
    const vod_id = this.item.id

    this.$emit('updateWatched', this.itemIdx)

    return axios({
      method: 'POST',
      baseURL: env.API_BASE_URL,
      url: `/api/v1/vod/${vod_id}/complate`,
    })
  },
}