import Vue from 'vue'
import VueRouter from 'vue-router'
import env from "../plugins/process.env"
import axios from "../plugins/axios"
import store from "../store"
import languages from '../assets/languages.json'

import Home from '../views/Home.vue'

Vue.use(VueRouter)

// 페이지 로드 완료
function pageLoaded() {
  store.state.pageLoaded = true
  return undefined
}

// 글로벌 라우터 가드 조건부 next() 실행
function beforeEachNext(next) {
  // 로그인 유무 확인 된 경우 && 진행중 스트림 객체 확인 된 경우
  if (store.state.user !== undefined && store.state.mainStream !== undefined) {
    next()
    return true
  }
}

const routes = [
  // 홈
  {
    path: '/',
    name: 'Home',
    component: Home
  },

  // 로그인
  {
    path: '/login',
    name: 'Login',
    component: () => import('../views/Login.vue'),
    beforeEnter(to, from, next) {
      store.state.user ? next('/') : next( pageLoaded() )
    }
  },

  // 로그아웃
  {
    path: '/logout',
    name: 'LogOut',
    component: () => import('../views/LogOut.vue'),
    beforeEnter(to, from, next) {
      !store.state.user ? next('/') : next()
    }
  },

  // 회원가입
  {
    path: '/join',
    name: 'Join',
    component: () => import('../views/Join.vue'),
    beforeEnter(to, from, next) {
      store.state.user ? next('/') : next( pageLoaded() )
    }
  },

  // ID/PW 찾기
  {
    path: '/inquiry/:mode',
    name: 'Inquiry',
    component: () => import('../views/Inquiry.vue'),
    beforeEnter(to, from, next) {
      const mode = to.params.mode

      // 정상적인 경로일 경우
      if (mode === 'id' || mode === 'pw') {
        return next( pageLoaded() )
      }
      return next('/')
    }
  },

  // PW 변겅
  {
    path: '/alteration/:uid',
    name: 'Alteration',
    component: () => import('../views/Alteration.vue'),
    beforeEnter(to, from, next) {
      const lang = store.state.lang
      const texts = languages.common.alert.text
      const token = to.params.uid
      const params = { token }

      return axios({
        method: 'GET',
        baseURL: env.API_BASE_URL,
        url: `/api/v1/auth/check`,
        params
      }).then( result => {
        const state = result.data.code

        if (state !== 200) {
          alert( texts.wrongAccess[lang] )
          return next('/')
        }
        next( pageLoaded() )
      }).catch( error => {
        const text = texts.error[lang] + `code: ${error}\nat: router /alteration`

        alert( text )
        error && console.error(error, error.response)
        next('/')
      })
    }
  },

  // 내 정보
  {
    path: '/account',
    name: 'Account',
    component: () => import('../views/Account.vue'),
    beforeEnter(to, from, next) {
      store.state.user ? next( pageLoaded() ) : next('/')
    }
  },

  // 다시보기
  {
    path: '/replay',
    name: 'Replay',
    component: () => import('../views/Replay.vue')
  },

  // 공지사항
  {
    path: '/notice',
    name: 'Notice',
    component: () => import('../views/Notice.vue')
  },

  // 스트리밍
  {
    path: '/stream/:uid',
    name: 'Stream',
    component: () => import('../views/Stream.vue'),
    beforeEnter(to, from, next) {
      store.state.user ? next() : next('/')
    }
  },

  // 404
  {
    path: '*',
    beforeEnter(to, from, next) {
      next('/')
    }
  }
]

const router = new VueRouter({
  // mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.afterEach(() => {
  const socket = store.state.socket

  if (!socket || !socket.connected) {
    setTimeout(() => {
      store.state.socketConnectWatcher = !store.state.socketConnectWatcher
    })
  }
})

router.beforeEach((to, from, next) => {
  // 모든 페이지는 정적인 페이지가 없고 서버에서 정보를 받아와야 함
  // 따라서 글로벌 라우팅 가드로 로딩 초기화
  store.state.pageLoaded = false
  store.state.drawer = false

  // 로그인 유무 확인 된 경우 && 진행중 스트림 객체 확인 된 경우
  if (beforeEachNext(next)) { return }

  // 개발모드일 경우 하위 로직 실행 안함
  if (env.DEV_MODE) {
    store.state.user = store.state.user ? store.state.user : null
    store.state.mainStream = store.state.mainStream ? store.state.mainStream : []
    store.state.pageLoaded = true
    store.state.drawer = true

    beforeEachNext(next)
    return
  }

  // 유저 정보 조회
  axios({
    method: 'GET',
    baseURL: env.API_BASE_URL,
    url: '/api/v1/user/info'
  }).then( result => {
    const state = result.data.code
    const user = result.data.result

    store.state.user = state !== 200 ? null : user
  }).catch( error => {
    console.error(error, error.response)
    store.state.user = null
  }).finally( () => beforeEachNext(next) )

  // 진행중 메인 스트림 조회
  axios({
    method: 'GET',
    baseURL: env.API_BASE_URL,
    url: `/api/v1/event/is_progress`
  }).then( result => {
    const state = result.data.code
    const stream = result.data.result.event

    store.state.mainStream = state !== 200 ? [] : stream
  }).catch( error => {
    console.error(error, error.response)
    store.state.mainStream = []
  }).finally( () => beforeEachNext(next) )
})

export default router
