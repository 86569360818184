<template>
  <v-sheet>
    <!--  툴바  -->
    <v-toolbar
      class="d-flex mt-n9 justify-end transparent"
      dense
      flat
    >
      <!--  이전 달 버튼  -->
      <v-btn
        fab
        text
        small
        color="grey darken-2"
        @click="prev"
      >
        <v-icon small>mdi-chevron-left</v-icon>
      </v-btn>

      <!--  켈린더 타이틀  -->
      <v-toolbar-title>
        {{ title }}
      </v-toolbar-title>

      <!--  다음 달 버튼  -->
      <v-btn
        fab
        text
        small
        color="grey darken-2"
        class="mr-n4"
        @click="next"
      >
        <v-icon small>mdi-chevron-right</v-icon>
      </v-btn>
    </v-toolbar>

    <!--  켈린더  -->
    <v-calendar
      v-model="calendar"
      ref="calendar"
      color="#DFDFDE"
      event-color="cyan"
      type="month"
      :event-more="false"
      :show-month-on-first="false"
      :events="items"
      @change="change"
      @click:event="eventClick"
    />
  </v-sheet>
</template>

<script>
export default {
  name: "Calendar",

  props: {
    items: {
      type: Array,
      default: () => ([])
    }
  },

  data: () => ({
    calendar: '',
    title: ''
  }),

  methods: {
    prev() {
      this.$refs.calendar.prev()
    },
    next() {
      this.$refs.calendar.next()
    },
    change() {
      this.title = this.$refs.calendar.title
    },
    eventClick(eve) {
      this.$emit('open', eve.event.info)
    }
  }
}
</script>

<style scoped>

</style>