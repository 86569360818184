<template>
  <v-app-bar
    :class="barClass"
    :dense="gc_bp.sm || gc_bp.xs"
    color="white"
    elevation="1"
    app
  >
    <!--  로고 모바일 중앙 정렬이 아닐 경우 중앙 정렬을 맞추기 위한 박스  -->
    <div
      v-if="!gc_bp.xs && !app_show"
      style="min-width: 172px;"
    />

    <v-spacer v-if="gc_bp.xs || !app_show"/>

    <app-bar-logo/>

    <v-spacer v-if="!gc_bp.xs && app_show"/>

    <app-bar-tabs v-if="!gc_bp.xs && app_show"/>

    <v-spacer/>

    <app-navigation-drawer-toggle-btn v-if="gc_bp.xs && !$store.state.drawer"/>

    <app-bar-right v-if="!gc_bp.xs"/>

  </v-app-bar>
</template>

<script>
import AppBarLogo from "./AppBarLogo"
import AppBarTabs from "./AppBarTabs"
import AppBarRight from "./AppBarRight"
import AppNavigationDrawerToggleBtn from "./AppNavigationDrawerToggleBtn"

export default {
  name: "AppBar",

  components: {
    AppBarLogo,
    AppBarTabs,
    AppBarRight,
    AppNavigationDrawerToggleBtn
  },

  computed: {
    barClass() {
      const base = 'px-md-4'
      const show = !this.$store.state.drawer || !this.gc_bp.xs
      const vis = show ? '' : 'c-vis-hidden'

      return [base, vis].join(' ')
    }
  }
}
</script>

<style lang="sass" scoped>
.c-vis-hidden
  visibility: hidden !important
</style>