// 메소드 네임 가이드
// api_${name}() {}

import methodsAPIAccount from "./methodsAPIAccount"
import methodsAPIHome from "./methodsAPIHome"
import methodsAPINotice from "./methodsAPINotice"
import methodsAPIReplay from "./methodsAPIReplay"
import methodsAPIStream from "./methodsAPIStream"

export default {
  // axios API 호출 시 정의되지 않은 호출 애러시 안내팝업 오픈
  _api_error(error) {
    const lang = this.gc_lang
    const title = this.gc_langText.common.alert.error[lang]
    const text = `${this.gc_langText.common.alert.text.error[lang]}\n${error}`

    console.error(error)

    this.gm_alertOpen({
      title,
      text
    })
  },

  ...methodsAPIAccount,

  ...methodsAPIHome,

  ...methodsAPINotice,

  ...methodsAPIReplay,

  ...methodsAPIStream
}