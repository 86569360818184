export default {
  // 기본 언어 코드
  lang: (function() {
    const l_lang = localStorage.getItem('lang')
    const n_lang = navigator.language
    // 사용자 지정 언어가 저장되어 있을 경우 해당 언어를
    // 아닐경우 브라우저 기본 언어
    const lang = l_lang ? l_lang :
      n_lang.match(/ko-/) ? 'ko' : 'en'

    // 주의!
    // 이곳에서는 단지 기본 언어 코드 정보를 추출할 뿐이므로
    // 사용자 지정 언어가 저장되어 있지 않다고
    // 임의로 브라우저 기본 언어를 사용자 지정 언어로 저장하지 않는다.

    return lang
  })(),

  // 스트리밍 또는 Vod 접속시 할당되는 랜덤 id (다중 시청 방지를 위한 랜덤 값, 유효 값 타입: Number)
  randomCID: null,

  // 페이지 로딩완료
  pageLoaded: false,

  // 모바일 전용 좌측 사이드바 메뉴
  drawer: false,

  // 유저 정보 객체
  //     undefined: 요청 필요
  //     null: 미로그인
  //     object: 로그인 상태 및 관련 정보 객체
  user: undefined,

  // 메인 스트림 객체 (진행중인 스트림 객체)
  //     undefined: 요청 필요
  //     array: length === 0, 진행중 스트림 없음
  //     array: length > 0, 진행중 스트림 객체
  mainStream: undefined,

  // 다중 스트리밍일 경우 띄울 팝업 리스트
  streamListShow: false,

  // 강제 로그아웃 모드
  forceLogOut: false,

  // 알림창 컨트롤 상태 관리 객체
  alert: {
    show: false,
    confirm: false,
    fullscreen: false,
    title: '',
    text: '',
    callback: null,
    width: 0,
    confirmAgreeText: '',
    confirmDisagreeText: '',
    closeText: ''
  },
}