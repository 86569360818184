<template>
  <v-dialog
    v-model="alert.show"
    :width="alert.width"
    :fullscreen="alert.fullscreen"
    scrollable
    persistent
  >

    <v-card tile>

      <v-card-title class="headline cyan white--text">
        {{ alert.title }}
      </v-card-title>

      <v-card-text class="py-5 c-alert-text">{{ alert.text }}</v-card-text>

      <v-divider/>

      <v-card-actions>
        <v-spacer/>

        <!--  확인 버튼  -->
        <v-btn
          v-if="!alert.confirm"
          color="cyan"
          outlined
          tile
          @click="gm_alertClose(true)"
        >
          {{ gc_langText.common.alert.closeText[gc_lang] }}
        </v-btn>

        <template v-else>
          <!--  예 버튼  -->
          <v-btn
            color="cyan"
            elevation="0"
            dark
            tile
            @click="gm_alertClose(true)"
          >
            {{ gc_langText.common.alert.confirmAgreeText[gc_lang] }}
          </v-btn>

          <!--  아니오 버튼  -->
          <v-btn
            plain
            tile
            @click="gm_alertClose(false)"
          >
            {{ gc_langText.common.alert.confirmDisagreeText[gc_lang] }}
          </v-btn>
        </template>

      </v-card-actions>

    </v-card>

  </v-dialog>
</template>

<script>
export default {
  name: "Alert",

  computed: {
    alert() {
      return this.$store.state.alert
    }
  }
}
</script>

<style lang="sass" scoped>
.c-alert-text
  white-space: pre-wrap
</style>