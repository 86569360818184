<template>
  <v-app :style="style">
    <v-progress-linear
      v-if="!gc_pageLoaded"
      id="pageLoadingUI"
      color="black"
      indeterminate
    />

    <template v-if="$store.state.user !== undefined">

      <app-navigation-drawer v-if="gc_viewMainUI"/>

      <app-bar v-if="gc_viewMainUI"/>

      <app-stream-list-dialog
        :_show="$store.state.streamListShow"
        @closeDialog="$store.state.streamListShow = false"
      />

      <v-main>
        <v-container
          class="fill-height justify-center"
          fluid
        >

          <router-view/>

          <alert/>

        </v-container>

        <!--  app-popup  -->

        <!--  app-dialog  -->

      </v-main>

      <app-footer v-if="gc_viewMainUI"/>

    </template>

    <!--  버튼: 소켓 스테이터스 디버깅용  -->
    <v-btn
      v-if="gc_user && gc_user.email === 'nice@inowing.net'"
      @click="io_emitDevDebug"
      color="orange"
      dark
      fixed
      right
      bottom
      fab
    >
      <v-icon>mdi-bug</v-icon>
    </v-btn>

  </v-app>
</template>

<script>
import '@/sass/overrides.sass'

import AppStreamListDialog from "./components/app/AppStreamListDialog"
import AppNavigationDrawer from "./components/app/AppNavigationDrawer"
import AppBar from "./components/app/AppBar"
import AppFooter from "./components/app/AppFooter"
import Alert from "./components/common/Alert"

export default {
  name: 'App',

  components: {
    AppStreamListDialog,
    AppNavigationDrawer,
    AppBar,
    AppFooter,
    Alert,
  },

  computed: {
    style() {
      const isHome = this.$route.name === 'Home'
      const loaded = this.gc_pageLoaded
      const styleHome = {
        'background-color': '#FFFFFF'
      }
      const styleOther = {
        'background-image': `url(${require('./assets/bg-img.svg')})`,
        'background-repeat': 'no-repeat',
        'background-size': 'cover',
        'background-position': 'right bottom',
      }

      return (isHome && loaded) ? styleHome : styleOther
    }
  },

  watch: {
    // 소켓 컨트롤
    '$store.state.user'() {
      this.io_disconnect()
    },

    // 소켓 연결 컨트롤
    '$store.state.socketConnectWatcher'() {
      this.io_connect()
    }
  }
}
</script>

<style lang="sass">
$grey: #DFDFDE
$grey-text: #B5B5B6
$cyan: #00AEAF
$blue: #2196F3
$light-blue-lighten-5: #E1F5FE
$grey-lighten-2: #E0E0E0

#pageLoadingUI
  position: absolute
  top: 0
  left: 0
  right: 0
  z-index: 9999

// v-tabs
.v-slide-group__prev--disabled,
.v-slide-group__next--disabled
  display: none !important
#replay_tabs.v-tabs
  .v-tab--active
    color: #FFFFFF !important
    background-color: $cyan !important
    border: 1px solid #ffffff

// v-carousel
.v-carousel__controls
  height: 64px !important
  .v-carousel__controls__item
    color: #E0DFE0 !important
    &.v-btn--active
      color: #009095 !important
      &::before
        display: none !important
    .v-icon
      opacity: 1 !important
@media (min-width: 600px) and (max-width: 1240px)
  .v-carousel__controls
    height: 48px !important
    .v-icon
      font-size: 16px !important
@media (max-width: 600px)
  .v-carousel__controls
    height: 32px !important
    .v-icon
      font-size: 14px !important

// Home.vue 에 사용되는 슬라이드 레이아웃
.c-d-flex
  .v-responsive__content
    display: flex
    flex-direction: column
    justify-content: flex-end !important
    >.row
      flex: 0 1 auto !important
.c-action-box
  width: 100%
  justify-content: space-between
  border: 1px solid #FFFFFF
  padding: 1.5rem 1rem
@media (min-width: 600px) and (max-width: 1240px)
  .c-action-box
    padding: 1rem .75rem
@media (max-width: 600px)
  .c-action-box
    padding: .5rem .5rem
.c-bd-black
  border: 1px solid #000000 !important

// v-chip
.v-chip.v-size--default
  height: 36px !important
.v-chip.v-size--small
  height: 28px !important

// v-simple-table
.v-data-table
  border-top: none !important
  border-bottom: 2px solid $grey !important
  thead
    background-color: $grey
    tr
      th
        color: #000000 !important
        font-size: .875rem !important
      th:nth-child(2)
        min-width: 300px
        width: 45%
      &:last-child th
        border-bottom: none !important
  tbody
    tr:not(:last-child) td
      border-bottom: thin solid $grey !important

// v-btn
.v-btn--plain
  .v-btn__content
    opacity: 1 !important

// v-pagination
.v-pagination
  button
    box-shadow: none !important

// v-input custom
.c-custom-input>.v-input__control>.v-input__slot
  align-items: baseline !important
  flex-direction: column !important
  label
    margin-bottom: 8px

// v-radio-group
[role="radiogroup"]
  justify-content: center

// v-calendar =================================================================
.v-calendar
  border-left: none !important
  .v-calendar-weekly__head
    .v-calendar-weekly__head-weekday
      padding: 4px 0
      background-color: $grey !important
      color: black !important
      font-weight: bold
      font-size: 14px !important
  .v-calendar-weekly__week
    .v-calendar-weekly__day:hover
      z-index: 2
      .v-event
        outline: thin solid #000000
    .v-calendar-weekly__day
      display: flex
      align-items: center
      justify-content: center
      position: relative
      height: 58px
      border-right: none !important
      .v-calendar-weekly__day-label:not(:last-child)
        .v-btn
          opacity: 1 !important
          background-color: $cyan !important
      .v-calendar-weekly__day-label
        margin: 0 !important
      .v-btn
        font-size: 14px !important
        font-weight: bold
      &.v-outside
        background-color: transparent !important
        .v-btn
          color: $grey-text !important
      .v-event
        position: absolute
        padding: 0 4px
        bottom: 2px
        min-width: calc(100% - 2px)
        width: auto !important
        margin: 0 !important
        text-align: center
        >div
          padding: 0 !important
</style>
