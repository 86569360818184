// 메소드 네임 가이드
// api_${name}() {}

import env from "../plugins/process.env"
import axios from "../plugins/axios"

export default {
  // 이벤트 리스트 요청
  api_getEventList() {
    const it = this

    return axios({
      method: 'GET',
      baseURL: env.API_BASE_URL,
      url: `/api/v1/event`
    }).then( result => {
      const obj = result.data.result

      if ( Array.isArray(obj.events) ) {
        it.banners = obj.banners.map( item => {
          item.banner = true
          return item
        })
        it.events = obj.events
        it.pasts = obj.past

        // iOS 데이트 파싱을 위한 처리
        const time = obj.server_time.split(/[-\s:]/g)
        time[1] = Number(time[1]) - 1

        it.curTime = +new Date( ...time )
      }
    }).finally( () => {
      it.$store.state.pageLoaded = true
    })
  },

  // 사전등록 신청
  api_attendEvent() {
    const it = this
    const mainStream = this.$store.state.mainStream || []
    let needUpdate = false

    return axios({
      method: 'POST',
      baseURL: env.API_BASE_URL,
      url: `/api/v1/event/${it.item.id}/attend`
    }).then( result => {
      const state = result.data.code

      if (state !== 200) { throw state }

      needUpdate = true
      it.item.is_attend = 1
      // 사전등록한 이벤트와 스트리밍 이벤트가 같을경우 스트리밍 이벤트 객체 업데이트
      mainStream.forEach(event => {
        if (event.id === it.item.id) {
          event.is_attend = 1
        }
      })
    }).catch(error => {
      it._api_error(`code: ${error}\nat: api_attendEvent`)
    }).finally( () => {
      // 이벤트 업데이트가 필요할 경우 업데이트 처리
      if (needUpdate) {
        it.$emit('updateEvents')
      }

      // 아닐 경우 즉시 업데이트 처리
      else {
        it.requestingAttend = false
      }
    })
  },
}