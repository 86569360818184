<template>
  <v-dialog
    v-model="show"
    transition="dialog-bottom-transition"
    width="480"
    :fullscreen="gc_bp.smAndDown"
    scrollable
    persistent
  >
    <v-card tile>

      <!--  닫기 버튼  -->
      <v-card-actions>
        <v-spacer/>
        <v-btn
          color="black"
          @click="show = false"
          text
        >
          <span class="mr-2">Close</span>
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-actions>

      <v-card-title class="pt-0 pb-4">

        <v-list
          class="c-w-100"
          two-line
          subheader
        >
          <v-list-item
            class="pl-2 c-outline"
            v-for="(item, idx) in list"
            :key="`stream-list-${idx}`"
            @click="goToStream(item)"
          >
            <!--  상태: 아이콘  -->
            <v-list-item-icon>
              <v-icon
                v-if="item.status === 1"
                color="red"
              >mdi-access-point-check</v-icon>
              <v-icon v-else>mdi-access-point-remove</v-icon>
            </v-list-item-icon>

            <!--  상태: 컨텐츠  -->
            <v-list-item-content>

              <!--  컨텐츠: 주제  -->
              <v-list-item-title>
                {{ item[`name${gc_lang_prefix}`] }}
              </v-list-item-title>

              <!--  컨텐츠: 발표자 / 장소  -->
              <v-list-item-subtitle
                v-if="item[`speaker${gc_lang_prefix}`] || item[`location${gc_lang_prefix}`]"
              >
                {{ item[`speaker${gc_lang_prefix}`] }}
                <span
                  class="px-1"
                  v-if="item[`speaker${gc_lang_prefix}`] && item[`location${gc_lang_prefix}`]"
                >/</span>
                {{ item[`location${gc_lang_prefix}`] }}
              </v-list-item-subtitle>

            </v-list-item-content>

          </v-list-item>
        </v-list>

      </v-card-title>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "AppStreamListDialog",

  props: {
    // 부모로부터 팝업 토글 여부를 받는다 (부모 속성이므로 직접 컨트롤 불가)
    _show: {
      type: Boolean,
      default: false
    },
  },

  data: () => ({
    // 팝업 토글 여부
    show: false,
  }),

  computed: {
    list() {
      return this.$store.state.mainStream
    }
  },

  watch: {
    _show(val) {
      this.show = val
    },

    show(val) {
      !val && this.$emit(`closeDialog`)
    }
  },

  methods: {
    // 스트리밍 페이지 이동 분기 처리
    goToStream(stream) {
      this.gm_goToStream(stream.id)
    }
  }
}
</script>

<style lang="sass" scoped>
.c-w-100
  width: 100%
.c-outline
  outline: 1px solid rgba(0, 0, 0, 0.12)
  &:not(:last-child)
    margin-bottom: 10px
</style>