import Vue from 'vue'
import Vuetify from 'vuetify/lib/framework'
import iconHome from '../icons/iconHome'
import iconPublication from '../icons/iconPublication'
import iconDemo from '../icons/iconDemo'

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    themes: {
      light: {
        secondary: '#B5B6B6',
      },
      dark: {
        secondary: '#B5B6B6',
      },
    },
  },
  icons: {
    values: {
      home: {
        component: iconHome,
      },
      publication: {
        component: iconPublication,
      },
      demo: {
        component: iconDemo,
      },
    }
  },
});
