<template>
  <v-footer
    class="px-3 py-0 justify-center c-link-text"
    color="grey"
  >

    <!--  노멀 푸터  -->
    <v-row
      v-if="showNormal"
      class="ma-0 px-0 c-layout"
    >

      <!--  링크: 뷰노 홈  -->
      <v-col
        v-if="links.length"
        class="d-flex py-0 align-center justify-sm-space-between flex-column flex-sm-row"
        cols="12"
      >
        <v-btn
          v-for="(item, i) in links"
          :key="`footer-link-${i}`"
          :href="item.href"
          class="ma-0 mt-3 white--text c-tt-none c-link-text"
          color="secondary"
          dark
          elevation="0"
          height="40"
          target="_blank"
          tile
          width="172"
        >
          <v-icon size="20">{{ item.icon }}</v-icon>
          <span class="pl-1">{{ item.text }}</span>
        </v-btn>
      </v-col>

      <v-col cols="12">
        <div class="d-sm-inline-block c-padding">VUNO Inc.</div>
        <div class="d-sm-inline-block c-padding">서울특별시 서초구 강남대로 479 신논현타워 9층</div>

        <br v-if="gc_bp.width < 1130 && gc_bp.sm">

        <div class="d-sm-inline-block c-padding">Tel<a href="tel:025156646">+82.2.515.6646</a></div>
        <div class="d-sm-inline-block c-padding">Fax<a href="#">+82.2.515.6647</a></div>
        <div class="d-sm-inline-block c-padding">Email<a href="mailto:hello@vuno.co">hello@vuno.co</a></div>

        <v-divider class="mt-3 c-border-color"/>
      </v-col>

      <v-col
        class="d-md-flex pt-0"
        cols="12"
      >
        <v-btn
          class="ml-n4 mt-n2 mb-2 c-link-text"
          color="secondary"
          dark
          text
          @click="openDialog"
        >
          Private & Cookie Policy
        </v-btn>

        <v-spacer/>

        <div class="c-padding">© VUNO Inc. All Rights Reserved.</div>
        <div class="c-padding">VUNO® Reg. KIPO., U.S. PTO & EUIPO</div>
        <div class="c-padding">VUNO Med® Reg. KIPO. & U.S. PTO</div>
      </v-col>
      <v-col>{{ snackbar }}</v-col>
    </v-row>

    <!--  노멀 푸터  -->
    <v-row
      v-else
      class="ma-0 py-4 c-layout"
    >
      <v-col class="d-flex justify-center">
        <div class="c-padding">© VUNO Inc. All Rights Reserved.</div>
      </v-col>
    </v-row>

    <!--  Private & Cookie Policy 팝업  -->
    <app-footer-dialog
      :_show="show"
      @closeDialog="show = false"
    />

    <!-- Private & Cookie Policy 방침 변경에 따른 알림 팝업 -->
    <v-snackbar v-model="snackbar" color="red" timeout="15000">
      2024-02-12 부로 개인정보 처리방침이 개정됩니다.
      <template v-slot:action="{ attrs }">
        <v-btn outlined small v-bind="attrs" @click="closeSnackbar">확인</v-btn>
      </template>
    </v-snackbar>

  </v-footer>
</template>

<script>
import AppFooterDialog from "./AppFooterDialog"

export default {
  name: "AppFooter",

  components: {
    AppFooterDialog,
  },

  data: (vm) => {
    return {
      // Private & Cookie Policy 팝업 오픈 관련 변수
      show: false,
      snackbar: vm.$store.state.showOldPrivate,
    }
  },

  computed: {
    showNormal() {
      const name = this.$route.name

      // 허용되는 라우터
      const valid = [
        'Home',
        'Account',
        'Replay',
        'Notice',
      ]
      return !valid.every(_name => name !== _name)
    },

    // 푸터에 표시할 링크버튼 리스트
    links() {
      const ko_lang = this.gc_isKoLang

      return [
        {
          href: 'https://www.vuno.co/',
          icon: '$home',
          text: 'VUNO Homepage',
        },
        {
          href: 'https://www.vuno.co/publication',
          icon: '$publication',
          text: 'Publications',
        },
        {
          href: ko_lang ? 'https://www.vuno.co/?requestdemo' : 'https://www.vuno.co/en/?requestdemo',
          icon: '$demo',
          text: 'Request a Demo',
        },
      ]
    },
  },

  methods: {
    // Private & Cookie Policy 팝업 오픈
    openDialog() {
      this.show = true
    },
    closeSnackbar() {
      this.snackbar = false
      this.show = true
    },
  },
}
</script>

<style lang="sass" scoped>
$text-color: #B5B6B6 !important
.c-padding
  color: $text-color
  padding-right: 24px

a
  margin-left: .5rem
  color: $text-color
  text-decoration: none

.c-padding:last-child
  padding-right: 0
  padding-bottom: 0

.c-link-text
  font-size: .75rem !important

.c-border-color
  border-color: $text-color
</style>
