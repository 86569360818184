<template>
  <v-carousel
    :height="carouselHeight"
    :show-arrows="false"
    :interval="5000"
    :hide-delimiters="items.length <= 1"
    cycle
    hide-delimiter-background
  >
    <!--  리스트: 없음  -->
    <v-carousel-item
      v-if="!items.length"
      style="background-color: #000"
    >
      <v-card
        class="c-layout transparent"
        :height="carouselHeight"
        flat
        tile
      />
    </v-carousel-item>

    <!--  리스트: 있음  -->
    <v-carousel-item
      v-for="(item, i) in items"
      :key="'carousel-' + i"
      :style="`background-color: ${item[`background_color${gc_lang_prefix}`]}`"
    >
      <v-card
        class="c-layout"
        flat
        tile
      >
        <v-img
          class="white--text align-stretch c-d-flex"
          :src="item[`photo_1${gc_lang_prefix}`]"
          :height="carouselHeight"
          :style="needCursor(item)"
          @click="clickBanner(item)"
        >
          <v-row
            v-if="!item.banner && gc_bp.width >= 600 && item.status !== 2"
            class="justify-center c-pb-84 c-mx-w560"
          >
            <v-card-actions class="c-action-box">

              <!--  D-Day 카운터  -->
              <v-chip
                class="mr-3 px-8 rounded-0 c-line-height-normal"
                color="transparent"
              >
                <span
                  v-if="gm_home_dDay(item.begin_at)"
                  class="d-flex align-center white--text"
                  v-html="gm_home_dDay(item.begin_at)"
                />
                <span
                  v-else-if="item.status === 1"
                  class="d-flex align-center white--text"
                >
                  <span class="c-d-day">D-Day</span>
                </span>
              </v-chip>

              <!--  종료된 강의  -->
              <v-btn
                v-if="item.status === 2"
                class="px-8 c-tt-none"
                color="cyan"
                elevation="0"
                @click.stop=""
                dark
                tile
              >
                {{ gc_langText.home.btn.ended[gc_lang] }}
              </v-btn>

              <!--  사전 등록 버튼  -->
              <v-btn
                v-else-if="item.is_attend === 0"
                class="px-3 c-tt-none c-l-bar"
                color="white"
                elevation="0"
                min-width="128"
                @click="$emit('open', item)"
                dark
                tile
                plain
              >
                {{ gc_langText.home.btn.preRegistration[gc_lang] }}
                <v-icon small>mdi-chevron-right</v-icon>
              </v-btn>

              <template v-else>

                <!--  시청 버튼  -->
                <v-btn
                  v-if="item.status === 1"
                  class="px-3 c-tt-none c-l-bar"
                  color="white"
                  elevation="0"
                  min-width="128"
                  @click="$emit('open', item)"
                  dark
                  tile
                  plain
                >
                  {{ gc_langText.home.btn.watch[gc_lang] }}
                </v-btn>

                <!--  사전등록완료  -->
                <v-btn
                  v-else-if="item.status === 0"
                  class="px-3 c-tt-none c-l-bar"
                  color="white"
                  elevation="0"
                  min-width="128"
                  @click="$emit('open', item)"
                  dark
                  tile
                  plain
                >
                  {{ gc_langText.home.btn.registered[gc_lang] }}
                </v-btn>

              </template>

            </v-card-actions>
          </v-row>
        </v-img>
      </v-card>
    </v-carousel-item>
  </v-carousel>
</template>

<script>
export default {
  name: "aCarousel",

  props: {
    items: {
      type: Array,
      default: () => ([])
    },
    curTime: {
      type: Number,
      default: 0
    }
  },

  computed: {
    carouselHeight() {
      const base = this.gc_bp.width > 1240 ? 1240 : this.gc_bp.width

      return base / 2.48
    }
  },

  methods: {
    // 배너 클릭시 이벤트 핸들러
    clickBanner(item) {
      const isBanner = item.banner
      const prefix = this.gc_lang_prefix
      const link = `${item[`link${prefix}`]}` && (()=>{
        const link = `${item[`link${prefix}`]}`
        const protocol = /^(http[s]{0,1}:){0,1}\/\//.test(link)

        return protocol ? link : `//${link}`
      })()
      const file = `${item[`file${prefix}`]}` && `${location.origin}${item[`file${prefix}`]}`

      // 액션이 필요할 경우
      if (isBanner && (link || file)) {
        window.open((link || file), '_blank')
      }
    },

    // 커서가 필요한지 판단하여 스타일 값 리턴
    needCursor(item) {
      const isBanner = item.banner
      const isLink = item[`link${this.gc_lang_prefix}`]
      const isFile = item[`file${this.gc_lang_prefix}`]
      const needCursorStyle = isBanner && (isLink || isFile)

      return needCursorStyle ? 'cursor: pointer' : ''
    }
  }
}
</script>

<style lang="sass" scoped>
.c-l-bar
  position: relative
  ::after
    position: absolute
    top: 50%
    left: -2rem
    height: 1rem
    border-left: 2px solid #ffffff
    transform: translateY(-50%)
    content: ''

.c-pb-84
  padding-bottom: 84px
.c-mx-w560
  width: 100%
  max-width: 560px
  margin-left: auto
  margin-right: auto

@media (min-width: 600px) and (max-width: 1240px)
  .c-pb-84
    padding-bottom: 60px
  .c-mx-w560
    max-width: 460px
@media (max-width: 600px)
  .c-pb-84
    padding-bottom: 44px
</style>