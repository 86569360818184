// 메소드 네임 가이드
// io_${name}() {}

import io from 'socket.io-client'
import env from "../plugins/process.env"

let last_user_id = null

export default {
  // 소켓 연결하기
  io_connect() {
    const it = this
    const the = this.$store.state
    const user = the.user
    const user_id = user ? user.id : 'guest'
    const randomCID = Math.floor( Math.random() * 100000000 )

    the.randomCID = randomCID

    // 소켓이 등록된 경우
    if (the.socket) {
      the.socket.io.opts.query = {
        user_id,
        randomCID
      }
      the.socket.connect()
    }

    // 소켓을 처음 등록하는 경우
    else {
      the.socket = io(`${env.API_BASE_URL}:${env.DEV_MODE ? '3003' : '3002'}`, {
        query: {
          user_id,
          randomCID
        }
      })

      the.socket.on('connect', () => it.io_eventsOnConnected() )

      the.socket.on('disconnect', () => {
        const validRoute = ['Stream']
        const needAction = validRoute.indexOf(it.$route.name) !== -1

        the.randomCID = null

        it.io_eventsOffDisconnected()

        // 유저 객체가 있을 경우 네트워크 끊김에 의한 소켓 끊김으로 판단
        if (it.gc_user && needAction) {
          const lang = this.gc_lang
          const text = this.gc_langText.common.alert.text.networkDisconnected[lang]

          // 스트리밍 페이지에서 실행될 경우 지역변수 변경
          if (name === 'Stream') { it.forceRouting = true }

          it.$router.push({ name: 'Home' })

          setTimeout(() => it.gm_alertOpen({
            text,
            callback: () => location.reload()
          }), 100)
        }
        // 소켓 재연결을 시도한다.
        // 로그인/로그아웃/서버사이드디스커넥트 일 경우 발동
        else {
          !the.randomCID && it.io_connect()
        }
      })
    }
  },

  // 소켓 연결끊기
  io_disconnect() {
    const socket = this.$store.state.socket
    socket && socket.disconnect()
  },

  // 중복 로그인 방지 이벤트 연결
  io_onDuplicateLogIn() {
    // 로그인 유저가 아닐 경우 리턴
    if (!this.$store.state.user) { return }

    const it = this
    const socket = this.$store.state.socket
    const user_id = this.$store.state.user.id
    const user_token = this.$store.state.user.last_login_token
    const event_name = `duplication_login_check:user_id_${user_id}:App\\Events\\User\\DuplicationLoginCheck`

    last_user_id = user_id
    socket.on(event_name, data => {
      const token = data.token

      if (user_token !== token) {
        it.$store.state.forceLogOut = true
        it.$router.push({ name: 'LogOut' })
      }
    })
  },

  // 중복 접속 방지 이벤트 리스닝 (로그인이 아닌 스트리밍 또는 Vod 와 같은 특정 페이지 접속 여부 판단)
  io_onDuplicateConnected() {
    const it = this
    const socket = this.$store.state.socket
    const user_id = this.$store.state.user.id
    const randomCID = it.$store.state.randomCID
    const event_name = `duplication_connected_check:user_id_${user_id}`

    // 정상적으로 소켓이 선언되지 않은 경우 페이지 리로드
    if (!socket) {
      return location.reload()
    }

    socket.on(event_name, data => {
      const name = it.$route.name
      const clientRandomCID = randomCID
      const serverRandomCID = data.randomCID

      if (clientRandomCID !== serverRandomCID) {
        // 스트리밍 페이지에서 실행될 경우 지역변수 변경
        if (name === 'Stream') { it.forceRouting = true }

        // id 가 다를 경우 메인 페이지로 리턴
        it.$router.push({ name: 'Home' })
      } else if (!it.duplicateConnectChecked) {
        // 아직 다중 접속 채크를 하지 않은 경우

        it.duplicateConnectChecked = true

        if (name === 'Stream') {
          it.io_eventsOn()
          it.api_streamUserIO(it.info.id, 'in')
        } else if (name === 'Replay') {
          it.api_getReplayItemList()
        }
      }
    })

    socket.emit('duplication_connected_check', { randomCID })
  },

  // 중복 접속 방지 이벤트 제거
  io_offDuplicateConnected() {
    const socket = this.$store.state.socket

    // 소켓이 이미 끊긴 상태일 경우 리턴
    if (!socket.connected) { return }

    const user_id = this.$store.state.user.id
    const event_name = `duplication_connected_check:user_id_${user_id}`

    socket.off(event_name)
  },

  // 중복 로그인 방지 이벤트 제거
  io_offDuplicateLogIn() {
    const socket = this.$store.state.socket
    // 마지막 유저 아이디가 없을 경우 리턴
    if (!last_user_id) { return }
    const event_name = `duplication_login_check:user_id_${last_user_id}:App\\Events\\User\\DuplicationLoginCheck`
    last_user_id = null

    socket.off(event_name)
  },

  // 소켓 이벤트 헨들러 등록 (스트림 페이지에서만 사용)
  io_eventsOn() {
    this.io_onEventConnectedCount()

    this.io_onEventChatMessage()
    this.io_onEventRemoveAllChatMessage()
    this.io_onEventSubtitleToggle()
    this.io_onEventCheckedToggle()
    this.io_onEventFilterToggle()
    this.io_onEventWatchQuestion()
    this.io_onEventForceAction()
  },

  // 소켓 이벤트 헨들러 삭제
  io_eventsOff() {
    const socket = this.$store.state.socket

    // 소켓이 이미 끊긴 상태일 경우 리턴
    if (!socket.connected) { return }

    const event_id = this.info.id

    const event_name_connectedCount = `event_stream_connected_counted:event_id_${event_id}`

    const event_name_chatMessage = `send_chat_message:event_id_${event_id}:App\\Events\\Chat\\SendMessage`
    const event_name_removeAllChatMessage = `delete_chat_message:event_id_${event_id}:App\\Events\\Chat\\DeleteMessage`
    const event_name_subtitleToggle = `change_active_subtitle:event_id_${event_id}:App\\Events\\Document\\ChangeActiveSubtitle`
    const event_name_filterToggle = `question_is_filtering:event_id_${event_id}:App\\Events\\QuestionToSpeaker\\QuestionChangeIsFiltering`
    const event_name_watchQuestion = `change_question_list:event_id_${event_id}:App\\Events\\QuestionToSpeaker\\ChangeQuestionList`
    const event_name_checkedToggle = `question_is_checked:event_id_${event_id}:App\\Events\\QuestionToSpeaker\\QuestionChangeIsChecked`
    const event_name_forceAction = `view_module_change:event_id_${event_id}:App\\Events\\Event\\ViewModuleChange`

    socket.off(event_name_connectedCount)
    this.io_emitConnectCount(-1)

    socket.off(event_name_chatMessage)
    socket.off(event_name_removeAllChatMessage)
    socket.off(event_name_subtitleToggle)
    socket.off(event_name_filterToggle)
    socket.off(event_name_watchQuestion)
    socket.off(event_name_checkedToggle)
    socket.off(event_name_forceAction)
  },

  // 소켓 기본 이벤트 헨들러 등록
  io_eventsOnConnected() {
    this.io_onEventDevDebug()
    this.io_onEventWatchStreamStatus()
    this.io_onDuplicateLogIn()
  },

  // 소켓 기본 이벤트 헨들러 삭제
  io_eventsOffDisconnected() {
    this.io_offEventDevDebug()
    this.io_offEventWatchStreamStatus()
    this.io_offDuplicateLogIn()
  },

  // 해당 이벤트 스트리밍 접속자 수 수신
  io_onEventConnectedCount() {
    const it = this
    const socket = this.$store.state.socket
    const event_id = this.info.id
    const event_name = `event_stream_connected_counted:event_id_${event_id}`

    socket.on(event_name, data => {
      it.accessorCount = data.count
    })

    it.io_emitConnectCount(1)
  },

  // 해당 이벤트 스트리밍 접속 종료 카운트
  io_emitConnectCount(count = 0) {
    const socket = this.$store.state.socket
    const event_id = this.info.id
    const randomCID = this.$store.state.randomCID

    socket.emit('event_stream_connected', { event_id, count, randomCID })
  },

  // 채팅 메세지 수신
  io_onEventChatMessage() {
    const it = this
    const socket = this.$store.state.socket
    const event_id = this.info.id
    const event_name = `send_chat_message:event_id_${event_id}:App\\Events\\Chat\\SendMessage`

    socket.on(event_name, data => {
      // 로컬 메세지가 있는지 인덱스 탐색
      const localIdx = it.chatPopup.messages.findIndex( doc => doc.local )
      // 소켓 통신으로 받는 메세지가 나의 것인지 여부
      const mine = data.message.user_id === it.gc_user.id

      // 소켓으로 받은 메세지가 나의 메세지이고 소켓 메세지로 전환해야 할 로컬 메세지가 있는 경우
      if (mine && localIdx > -1) {
        it.chatPopup.messages[localIdx] = data.message
      } else {
        it.chatPopup.messages.push( data.message )

        // 채팅창이 닫혀 있을 경우 뱃지 개수를 늘려서 뱃지 노출
        if (!it.chatPopup.show) {
          it.chatPopup.badge++
        }
      }
    })
  },

  // 모든 채팅 메세지 삭제
  io_onEventRemoveAllChatMessage() {
    const it = this
    const socket = this.$store.state.socket
    const event_id = this.info.id
    const event_name = `delete_chat_message:event_id_${event_id}:App\\Events\\Chat\\DeleteMessage`

    socket.on(event_name, () => {
      it.chatPopup.messages = []
    })
  },

  // 강제 행동 이벤트
  io_onEventForceAction() {
    const it = this
    const socket = this.$store.state.socket
    const event_id = this.info.id
    const event_name = `view_module_change:event_id_${event_id}:App\\Events\\Event\\ViewModuleChange`

    socket.on(event_name, data => {
      const id = data.module.id
      const index = it.tabList.findIndex( item => id === item.id )

      it.setTab(index)
    })
  },

  // 질문 토글 수신
  io_onEventSubtitleToggle() {
    const it = this
    const socket = this.$store.state.socket
    const event_id = this.info.id
    const event_name = `question_use_subtitle:event_id_${event_id}:App\\Events\\QuestionToSpeaker\\QuestionChangeUseSubtitle`

    socket.on(event_name, data => {
      const question = data.question
      const show = !!question.is_use_subtitle

      // 웨비나 컴포넌트 내 값 변경
      it.question.active = show ? question.contents : ''

      // 질문 리스트 객체 내 값 변경
      it.question.list.forEach( doc => {
        const same = doc.id === question.id

        doc.is_use_subtitle = same ? question.is_use_subtitle : 0
      })
    })
  },

  // 질문 필터 토글 (필터는 준 관리자 리스트에 보일지 여부를 나타내는 플레그임)
  io_onEventFilterToggle() {
    const it = this
    const socket = this.$store.state.socket
    const event_id = this.info.id
    const event_name = `question_is_filtering:event_id_${event_id}:App\\Events\\QuestionToSpeaker\\QuestionChangeIsFiltering`

    socket.on(event_name, data => {
      // 유저 레벨이 40 이 아닐경우 종료
      // 현재 40이 아닐 경우 종료로 설정되어 있지만 실제 사용하고 있지는 않은 로직
      if (it.gc_user.admin_level !== 40) { return }

      const question = data.question
      const list = it.question.list
      const i = list.findIndex( doc => doc.id === question.id )

      if (i === -1) {
        // 해당 질문이 리스트에 없을 경우 추가
        list.push(question)
      } else {
        // 그 반대의 경우 리스트에서 해당 질문 삭제
        // 변수 반응형 적용문제로 splice 나 slice 를 사용하지 않고 filter 로 전체 루프를 돈다...
        it.question.list = list.filter( doc => doc.id !== question.id )
      }
    })
  },

  // 질문 채크박스 토글 수신
  io_onEventCheckedToggle() {
    const it = this
    const socket = this.$store.state.socket
    const event_id = this.info.id
    const event_name = `question_is_checked:event_id_${event_id}:App\\Events\\QuestionToSpeaker\\QuestionChangeIsChecked`

    socket.on(event_name, data => {
      const question = data.question

      // 질문 리스트 객체 내 채크 값 변경
      it.question.list.forEach( doc => {
        const same = doc.id === question.id

        if (same) {
          doc.is_checked = question.is_checked
        }
      })
    })
  },

  // 질문 생성/수정/삭제 신호 수신
  io_onEventWatchQuestion() {
    const it = this
    const socket = this.$store.state.socket
    const event_id = this.info.id
    const event_name = `change_question_list:event_id_${event_id}:App\\Events\\QuestionToSpeaker\\ChangeQuestionList`

    socket.on(event_name, data => {
      const list = it.question.list
      const mode = data.status
      const question = data.question
      const show = !!question.is_use_subtitle

      if (mode === 'create') {
        // 유저 레벨이 40 미만일 경우에만 즉시 리스트에 푸쉬한다
        const needPush = it.gc_user.admin_level < 40

        needPush && list.push(question)
      }
      else if (mode === 'update') {
        list.forEach( doc => {
          if (doc.id === question.id) {
            doc.contents = question.contents
          }
        })
        // 웨비나 컴포넌트 내 값 변경
        it.question.active = show ? question.contents : ''
      }
      else if (mode === 'delete') {
        it.question.list = list.filter( doc => doc.id !== question.id )
      }
    })
  },

  // 디버깅용 이벤트 헨들러
  io_onEventDevDebug() {
    const socket = this.$store.state.socket
    const event_name = `dev_debug`

    socket.on(event_name, data => {
      console.log(data)
    })
  },

  // 디버깅용 이벤트 헨들러 삭제
  io_offEventDevDebug() {
    const socket = this.$store.state.socket
    const event_name = `dev_debug`

    socket.off(event_name)
  },

  // 디버깅 객체 요청
  io_emitDevDebug() {
    const socket = this.$store.state.socket

    socket && socket.emit('dev_debug')
  },

  // 스트림 라이브 상태 변경 감지
  io_onEventWatchStreamStatus() {
    const the = this.$store.state
    const socket = the.socket
    const event_name = `event_status_change:App\\Events\\Event\\EventStatusChange`

    socket.on(event_name, data => {
      const event = data.event
      let changed = false

      // 기존 리스트에 해당하는 스트리밍을 찾아 정보 변경
      the.mainStream.forEach( item => {
        if (item.id === event.id) {
          item.status = event.status
          changed = true
        }
      })

      // 기존 리스트에서 변경을 하지 못했을 경우 신규 스트리밍으로 판단하고 추가
      if (!changed) {
        the.mainStream.push(event)
      }
    })
  },

  // 스트림 라이브 상태 변경 감지 이벤트 삭제
  io_offEventWatchStreamStatus() {
    const socket = this.$store.state.socket
    const event_name = `event_status_change:App\\Events\\Event\\EventStatusChange`

    socket.off(event_name)
  }
}