// 메소드 네임 가이드
// gm_home_${name}() {}

export default {
  // d-day 카운트
  gm_home_dDay(dDay) {
    if (!dDay) { return '' }

    // iOS 데이트 파싱을 위한 처리
    const time = dDay.split(/[-\s:]/g)
    time[1] = Number(time[1]) - 1

    const _gap = new Date( ...time ) - this.curTime

    // 잘못된 값이거나 d-day 를 초과할 경우 리턴
    if (Number.isNaN(_gap) || _gap < 0) { return '' }

    // d-day 카운트 값
    let gap = Math.floor(_gap / 1000) // 초 단위

    // 분 카운트
    gap = Math.floor(gap / 60) // 분 단위
    const min = (gap % 60).toString().padStart(2, '0')

    // 시 카운트
    gap = Math.floor(gap / 60) // 시 단위
    const hour = (gap % 24).toString().padStart(2, '0')

    // 일 카운트
    gap = Math.floor(gap / 24) // 시 단위
    const day = gap.toString().padStart(2, '0')

    return `<span class="c-d-day">D-${ day }</span><span class="ml-3">${ hour }<span class="c-sec-count">:</span>${ min }</span>`
  }
}