<template>
  <!--
    기본 레이아웃 구조
    v-row ------- 컨테이너 역할
      v-col ----- 실질적 row 역할
        v-row --- col 레퍼 역할
          v-col - col
    추천되는 구조는 아니지만 wide 스타일의 row 내에 있는 컨텐츠를
    non-wide 스타일로 보여주기 위함임
  -->
  <v-row
    v-if="gc_pageLoaded"
    class="align-self-start"
  >

    <!--  row 1  -->
    <v-col
      class="py-0 grey lighten-2"
      cols="12"
    >
      <v-row class="my-0">
        <v-col
          class="pa-0"
          cols="12"
        >

          <!--  슬라이더  -->
          <carousel
            :curTime="curTime"
            :items="itemsForCarousel"
            @open="streamLiveOpen"
          />

        </v-col>
      </v-row>
    </v-col>

    <!--  row 2  -->
    <v-col
      class="pb-8 white"
      cols="12"
    >
      <v-row class="c-layout">

        <!--  이벤트 리스트  -->
        <v-col
          class="pt-10"
          cols="12"
          lg="7"
        >
          <!--  다가오는 이벤트 리스트  -->
          <h1 class="pb-3 text-h5 font-weight-bold">
            Upcoming Events
          </h1>

          <table-list
            :items="itemsForList"
            @streamLiveOpen="streamLiveOpen"
          />

          <!--  종료된 이벤트 리스트  -->
          <h1 class="pt-12 pb-3 text-h5 font-weight-bold">
            Past Events
          </h1>

          <table-list
            :items="pasts"
          />
        </v-col>

        <!--  켈린더  -->
        <v-col
          class="pt-10"
          cols="12"
          lg="5"
        >
          <h1 class="text-h5 font-weight-bold">
            Calendar
          </h1>

          <calendar
            :items="itemsForCalendar"
            @open="streamLiveOpen"
          />
        </v-col>

      </v-row>
    </v-col>

    <!--  이벤트 상세 팝업  -->
    <register-dialog
      :_show="event.show"
      :_item="event.info"
      :curTime="curTime"
      :updatedTick="updatedTick"
      @eventClose="eventClose"
      @updateEvents="updateEventList"
    />

  </v-row>
</template>

<script>
import Carousel from "../components/home/Carousel"
import TableList from "../components/home/TableList"
import Calendar from "../components/home/Calendar"
import RegisterDialog from "../components/home/RegisterDialog"

export default {
  name: 'Home',

  mounted() {
    this.intervalId = setInterval(this.incCurTime, 60000)
    this.api_getEventList()
  },

  destroyed() {
    clearInterval(this.intervalId)
  },

  components: {
    Carousel,
    TableList,
    Calendar,
    RegisterDialog
  },

  data: () => ({
    // 현재시각 증가 인터벌 핸들러
    intervalId: 0,

    // 서버 현재 시각 (ms)
    curTime: 0,

    // 서버로부터 받아온 기본 배너 정보
    banners: [],

    // 서버로부터 받은 다가오는 스트리밍 이벤트 객체 리스트
    events: [],

    // 상세 팝업 띄울 단일 이벤트에 대한 객체
    event: {
      show: false,
      info: {}
    },

    // 종료된 스트리밍 이벤트 객체 리스트
    pasts: [],

    // 상세 팝업에 업데이트 유무를 알려주기 위한 의미없는 토글장치
    updatedTick: false
  }),

  computed: {
    // 켈린더 컴포넌트 내에서 사용될 데이터 포멧으로 변환
    itemsForCalendar() {
      const gc_lang_prefix = this.gc_lang_prefix

      return this.events
        .filter( item => (Boolean(item.is_show_event) || Boolean(item.is_show_banner)) )
        .map( item => {
          const category = item.category || {}
          // iOS 지원을 위한 피싱
          const start = item.begin_at.split(/[-\s:]/g)
          const end = item.end_at.split(/[-\s:]/g)

          // month 값 인덱스 형식으로 변경
          start[1] = Number(start[1]) - 1
          end[1] = Number(end[1]) - 1

          return {
            start: new Date( ...start ), // 이벤트 시작 날짜 및 시각 (필수)
            end: new Date( ...end ), // 이벤트 종료 날짜 및 시각 (옵션, 기본값 start)
            name: category[`name${gc_lang_prefix}`] || '', // 이벤트 이름 (옵션)
            info: item, // 커스텀 데이터 (이벤트 클릭시 조회해서 사용하기 위한 이벤트 객체)
          }
      })
    },

    // 슬라이더 컴포넌트 내에서 사용될 데이터 포멧으로 변환
    itemsForCarousel() {
      const banners = this.banners.map( item => item )
      const events = this.events.filter( item => Boolean(item.is_show_banner) )

      return events.concat(banners)
    },

    // 다가오는 이벤트 리스트에 사용될 필터링 된 리스트
    itemsForList() {
      return this.events.filter( item => {
        return Boolean(item.is_show_event)
      })
    }
  },

  methods: {
    // 이벤트 리스트 업데이트
    updateEventList() {
      const it = this
      const lang = this.gc_lang
      const text = this.gc_langText.common.alert.text.attendSuccess

      this.api_getEventList().finally( () => {
        it.gm_alertOpen({
          text: text[lang],
          callback: () => it.updatedTick = !it.updatedTick
        })
      })
    },

    // 이벤트 상세 팝업 오픈
    streamLiveOpen(info) {
      const it = this

      // 로그인이 필요한 사용자
      if (!this.$store.state.user) {
        const text = this.gc_langText.common.alert.text.needSignIn[this.gc_lang]
        const loginText = this.gc_langText.common.account.signIn[this.gc_lang]
        const closeText2 = this.gc_langText.common.alert.closeText2[this.gc_lang]

        this.gm_alertOpen({
          text,
          confirm: true,
          confirmAgreeText: loginText,
          confirmDisagreeText: closeText2,
          callback: () => it.$router.push(({ name: 'Login' }))
        })
        return
      }

      this.event.show = true
      this.event.info = info
    },

    // 이벤트 상세 팝업 닫기
    eventClose() {
      this.event.show = false
      this.event.info = {}
    },

    // 현재 시각 1분씩 증가
    incCurTime() {
      if (this.curTime) {
        this.curTime += 60000
      }
    }
  }
}
</script>

<style lang="sass" scoped>

</style>
