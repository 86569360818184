// 컴퓨티드 네임 가이드
// stream_${name}() {}

export default {
  // 보여야 할 탭 타입
  stream_tabType() {
    return ['webinar', 'slide', 'link', 'questiontospeaker']
  },

  // 최초 활성화 되어야 할 탭
  stream_tabDefaultActiveType() {
    return ['webinar']
  }
}