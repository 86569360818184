<template>
  <v-btn
    class="ma-0"
    style="position: absolute; right: 0;"
    color="#1A2B4C"
    @click="$store.state.drawer = !$store.state.drawer"
    x-large
    icon
  >
    <v-icon x-large>mdi-view-grid</v-icon>
  </v-btn>
</template>

<script>
export default {
  name: "AppNavigationDrawerToggleBtn"
}
</script>

<style lang="sass" scoped>
</style>