// 컴퓨티드 네임 가이드
// gc_${name}() {}
import languages from '../assets/languages.json'

export default {
  // SSO: 버튼 텍스트
  gc_SSOText() {
    return this.gc_lang === 'ko' ? '법정의무교육' : '법정의무교육'
  },
  // vuetify 브레이크 포인트
  gc_bp() {
    return this.$vuetify.breakpoint
  },

  // 유저 정보 객체
  gc_user() {
    return this.$store.state.user
  },

  // 메인 스트림 객체
  gc_mainStream() {
    return this.$store.state.mainStream || []
  },

  // 페이지 로딩 완료
  gc_pageLoaded() {
    return this.$store.state.pageLoaded
  },

  // 메인 레이아웃 UI 노출 여부
  gc_viewMainUI() {
    return this.$store.state.user !== undefined &&
      this.$store.state.mainStream !== undefined
  },

  // 한국어 선택 상태
  gc_isKoLang() {
    return this.$store.state.lang === 'ko'
  },

  // 현재 언어
  gc_lang() {
    return this.$store.state.lang
  },

  // 서버 결과값에 붙을 언어 접두사
  gc_lang_prefix() {
    return this.gc_lang === 'ko' ? '' : '_en'
  },

  // 페이지 별 언어 json 객체
  gc_langText() {
    return languages
  },

  // form 유효성 검사
  gc_rules() {
    const lang = this.gc_lang
    const valid = this.gc_langText.common.valid

    return {
      required: value => !!value || valid.required[lang],

      requiredOptions: value => !!value || valid.requiredOptions[lang],

      requiredArray: value => !!value.length || valid.required[lang],

      mail: value => {
        const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

        return pattern.test(value) || valid.mail[lang]
      },

      mailFront: value => {
        const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))$/

        return pattern.test(value) || valid.mailFront[lang]
      },

      mailBack: value => {
        const pattern = /^((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

        return pattern.test(value) || valid.mailBack[lang]
      },

      phone: value => {
        const pattern = /^(01[0-9])[0-9]{7,8}$/

        return pattern.test(value) || valid.phone[lang]
      },

      phoneAuth: () => {
        return this.form.phoneAuth || valid.phoneAuth[lang]
      },

      pwLength: value => {
        return value.length >= 6 || valid.pwTooShort[lang]
      },

      pwEqual: () => {
        const pw = this.form.pw
        const pwr = this.form.pwr
        const equal = pw === pwr
        // 둘 중 하나라도 길이가 0 일 경우
        // 아직 비밀번호 관련 인풋창을 다 채우지 않은 것으로 판단하고 페스
        const noLength = !pw.length || !pwr.length

        return equal || noLength || valid.pwUnequal[lang]
      }
    }
  },
}