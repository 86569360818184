// 메소드 네임 가이드
// api_${name}() {}

import env from "../plugins/process.env"
import axios from "../plugins/axios"

export default {
  // 로그인
  api_signIn(params) {
    const it = this
    const store = this.$store.state
    const lang = this.gc_lang
    const texts = this.gc_langText.login.alert

    store.mainStream = undefined

    return axios({
      method: 'POST',
      baseURL: env.API_BASE_URL,
      url: '/api/v1/auth/login',
      params
    }).then( result => {
      const state = result.data.code
      const user = result.data.result

      if (state === 200) {
        store.user = user
        location.href = '/'
      } else if (state === 410) {
        it.gm_alertOpen({
          title: texts.unauthorized.title[lang],
          text: texts.unauthorized.text[lang]
        })
      } else {
        it.gm_alertOpen({
          title: texts.fail.title[lang],
          text: texts.fail.text[lang]
        })
      }
    }).catch(error => {
      it._api_error(`code: ${error}\nat: api_signIn`)
    }).finally(() => {
      if (it.requesting !== undefined) {
        it.requesting = false
      }
    })
  },

  // 로그아웃
  api_signOut() {
    const lang = this.gc_lang
    const text = this.gc_langText.logout.text.forceLogOuted[lang]
    const force = this.$store.state.forceLogOut

    return axios({
      method: 'GET',
      baseURL: env.API_BASE_URL,
      url: '/api/v1/auth/logout'
    }).catch( () => {} ).finally(() => {
      force && alert(text)
      location.href = '/'
    })
  },

  // 회원가입
  api_signUp(params) {
    return axios({
      method: 'POST',
      baseURL: env.API_BASE_URL,
      url: '/api/v1/register',
      params
    })
  },

  // 회원정보 수정
  api_updateUser(params, id) {
    const it = this
    const store = this.$store.state
    const lang = this.gc_lang
    const texts = this.gc_langText.account.alert.success

    it.requesting = true

    return axios({
      method: 'POST',
      baseURL: env.API_BASE_URL,
      url: `/api/v1/user/${id}`,
      params
    }).then( result => {
      const state = result.data.code

      if (state !== 200) { throw state }

      store.user = result.data.result
      it.gm_alertOpen({
        text: texts[lang],
        callback: () => location.reload()
      })
    }).catch(error => {
      it._api_error(`code: ${error}\nat: api_updateUser`)
    }).finally(() => {
      it.requesting = false
    })
  },

  // 이메일 찾기
  api_findEmail() {
    const it = this
    const params = {
      name : it.form.name,
      phone: it.form.phone
    }

    it.form.name = ''
    it.form.phone = ''

    it.requesting = true

    return axios({
      method: 'GET',
      baseURL: env.API_BASE_URL,
      url: `/api/v1/auth/find_email`,
      params
    }).then( result => {
      const state = result.data.code

      if (state !== 200 && state !== 410) { throw state }

      const arr = result.data.result

      it.response = Array.isArray(arr) ? arr : []

    }).catch(error => {
      it._api_error(`code: ${error}\nat: api_findEmail`)
    }).finally( () => {
      it.requesting = false
    })
  },

  // 비밀번호 찾기 위한 이메일 발송
  api_findPassword() {
    const it = this
    const params = {
      name : it.form.name,
      email: `${it.form.mailFront}@${it.form.mailBack}`
    }

    it.requesting = true

    return axios({
      method: 'GET',
      baseURL: env.API_BASE_URL,
      url: `/api/v1/auth/remin_password`,
      params
    }).then(result => {
      const found = result.data.code === 200
      const lang = it.gc_lang
      const text = found ?
        it.gc_langText.inquiry.sentEmail[lang] :
        it.gc_langText.inquiry.unsentEmail[lang]

      it.gm_alertOpen({
        text,
        callback: () => it.$router.push( { name: 'Home' } )
      })

    }).catch(error => {
      it._api_error(`code: ${error}\nat: api_findPassword`)
    }).finally(() => {
      it.requesting = false
    })
  },

  // 비로그인 비밀번호 수정
  api_changePasswordForce() {
    const it = this
    const params = {
      token: it.$route.params.uid,
      password: it.form.pw
    }

    it.requesting = true

    return axios({
      method: 'POST',
      baseURL: env.API_BASE_URL,
      url: `/api/v1/auth/change_password`,
      params
    }).then( result => {
      const state = result.data.code
      const texts = it.gc_langText.common.alert.text
      let text = ''

      if (state === 200) {
        text = texts.passwordChange[it.gc_lang]
      } else {
        text = texts.wrongAccess[it.gc_lang]
      }

      it.gm_alertOpen({
        text,
        callback: () => it.$router.push( { name: 'Home' } )
      })
    }).catch(error => {
      it._api_error(`code: ${error}\nat: api_changePasswordForce`)
    }).finally(() => {
      it.requesting = false
    })
  }
}