<template>
  <v-dialog
    v-model="show"
    transition="dialog-bottom-transition"
    width="900"
    :fullscreen="gc_bp.smAndDown"
    scrollable
  >
    <v-card tile>

      <!--  닫기 버튼  -->
      <v-card-actions>
        <v-spacer/>
        <v-btn
          color="black"
          @click="show = false"
          text
        >
          <span class="mr-2">Close</span>
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-actions>

      <!--  본문: 한글  -->
      <v-card-text
        v-if="gc_isKoLang"
        class="c-article"
      >
        <PrivateKo/>
      </v-card-text>

      <!--  본문: 영문  -->
      <v-card-text
        v-else
        class="c-article"
      >
        <PrivateEn/>
      </v-card-text>

    </v-card>
  </v-dialog>
</template>

<script>
import PrivateKo from '@/components/ref/PrivateKo.vue'
import PrivateEn from '@/components/ref/PrivateEn.vue'

export default {
  name: "AppFooterDialog",
  components: { PrivateEn, PrivateKo },

  props: {
    // 부모로부터 팝업 토글 여부를 받는다 (부모 속성이므로 직접 컨트롤 불가)
    _show: {
      type: Boolean,
      default: false
    },
  },

  data: () => ({
    // 팝업 토글 여부
    show: false,
  }),

  watch: {
    _show(val) {
      this.show = val
    },

    show(val) {
      !val && this.$emit(`closeDialog`)
    }
  }
}
</script>

<style lang="sass" scoped>
.c-article
  font-size: 1rem
  word-break: keep-all
  color: black !important
  padding: 0 54px 64px !important
h3
  font-size: 1.5rem
  line-height: 1.2
  margin: 1.5rem 0
p
  margin: .3rem 0
  white-space: break-spaces
  &::after
    content: ' '
ol, ul
  margin: .5em 0
  padding: 0 30px
  li
    line-height: 1.6
table
  width: 100% !important
  border-collapse: collapse
  td
    padding: 10px
</style>
