<template>
  <div
    class="d-flex subtitle-2 text-center text-no-wrap"
    style="min-width: 172px;"
  >
    <v-row class="ma-0 align-center">
      <!--  언어 설정  -->
      <v-col
        class="d-flex pa-0 justify-end"
        cols="12"
      >
        <v-btn
          :class="'px-0 subtitle-2' + (gc_isKoLang ? ' cyan' : '')"
          text
          x-small
          :dark="gc_isKoLang"
          @click="gm_changeLang('ko')"
        >
          KO
        </v-btn>
        <v-btn
          :class="'px-0 subtitle-2' + (!gc_isKoLang ? ' cyan' : '')"
          text
          x-small
          :dark="!gc_isKoLang"
          @click="gm_changeLang('en')"
        >
          EN
        </v-btn>
      </v-col>

      <template v-if="app_show">

        <!--  계정 관련 좌측 메뉴  -->
        <v-col
          class="d-flex pa-0 pt-1 justify-end"
          cols="12"
        >
          <v-btn
            v-if="$store.state.user"
            class="px-2 py-0 c-wh-auto c-divider"
            to='/logout'
            plain
          >
            {{ gc_langText.common.account.signOut[gc_lang] }}
          </v-btn>
          <v-btn
            v-else
            class="px-2 py-0 c-wh-auto c-divider"
            to="/login"
            plain
          >
            {{ gc_langText.common.account.signIn[gc_lang] }}
          </v-btn>

          <v-btn
            v-if="$store.state.user"
            class="px-2 py-0 c-wh-auto"
            :to="app_accountLink"
            plain
          >
            {{ gc_langText.app.account.options[gc_lang] }}
          </v-btn>
          <v-btn
            v-else
            class="px-2 py-0 c-wh-auto"
            to="/join"
            plain
          >
            {{ gc_langText.app.account.join[gc_lang] }}
          </v-btn>
        </v-col>

      </template>

    </v-row>
  </div>
</template>

<script>
export default {
  name: "AppBarRight"
}
</script>

<style lang="sass" scoped>
.c-wh-auto
  min-width: auto !important
  height: auto !important
</style>