<template>
  <div class="ckeditor_content init-css">

    <h3 class="font-700"><strong>Private &amp; Cookie Policy</strong></h3>

    <p>&nbsp;</p>

    <p>VUNO Inc. (hereinafter referred to as the "Company") has this Privacy Policy (hereinafter referred to as the
      "Policy”) in place to protect user privacy, rights and interests; and handle user complaints relevant to personal
      information in accordance with the Personal Information Protection Act.</p>

    <p>※ This Policy, as partially modified and updated, goes into effect on November 9 2020.</p>

    <p>&nbsp;</p>

    <p><strong>Article 1 Purpose of Processing Personal Information</strong></p>

    <p>The Company processes personal information for the following purposes.</p>

    <p>&nbsp;</p>

    <ol>
      <li>To provide the Company's services: Provision of the Company's services owing to offline product purchase and
        purchase inquiry; prevention of service misappropriation; sending of notifications including e-mailing; product
        delivery; dispatch of contracts and invoices; payment and settlement of fees; provision of customized services
        including newsletters; etc.
      </li>
      <li>To deal with collaborative tasks and external activities concerning research and public relations: Sharing
        information on products and product demonstration events organized by the Company; preliminary review of
        identification and other matters to entrust services such as research, speeches and consultation; signing and
        fulfillment of the pertinent contracts; tax withholding; product-related marketing and research activities; etc.
      </li>
      <li>To recruit employees: Execution of applicant screenings and sending of notifications for each step of the
        pertinent process; review of job candidates for year-round hiring who agree to register for VUNO Talent Pool;
        handling of complaints and sending of notifications on the results thereof in the event of inquiries being made
        on recruitment; etc.
      </li>
      <li>To handle complaints and product information including safety thereof: Answering product inquiries;
        identification check of a person who lodges a complaint; review of complaint details; contacting the said person
        for fact-finding inspection and notifications on the pertinent results; reporting, assessment and handling of
        matters on product safety and quality complaints; etc.
      </li>
      <li>To fulfill legal and administrative obligations: Fulfillment of obligations to report and submit documentation
        of clinical testing and post-market safety management including post market surveillance pursuant to relevant
        statutes including the Medical Service Act, the Medical Devices Act and the Bioethics and Safety Act;
        declaration and payment of taxes such as corporate tax and value added tax; issuance and transfer of bills,
        receipts and tax invoices; delivering on legal and administrative obligations according to relevant statutes,
        and orders and commands issued by administrative authorities and other related agencies; etc.
      </li>
      <li>To manage visual data processing: Provision of on-premises security; prevention of fire and crime; etc.</li>
    </ol>

    <p>&nbsp;</p>

    <p>The Company processes de-identified data for the purpose of research and development of AI software programs.
      Health and medical records that are used for research purposes do not include any personally identifiable
      information such as a full name and phone number. The Company uses such data for scientific research only.</p>

    <p>&nbsp;</p>

    <p>The Company may use and provide personal information of a data subject within the scope reasonably related to the
      purposes for which the said personal information was initially collected, in accordance with the Personal
      Information Protection Act taking into consideration whether disadvantages are caused to the data subject; whether
      necessary measures to secure safety such as encryption have been taken; etc. Use and provision of personal
      information is firmly based on the Company's comprehensive consideration into miscellaneous circumstances: The
      Personal Information Protection Act and other relevant statutes; The purposes and methods of using and providing
      personal information; Particulars of personal information to be used and provided;</p>

    <p>Particulars of personal information in the event that consent for use and provision thereof is obtained from a
      data subject; or that any notification or disclosure has been made to a data subject thereon; The impact on the
      privacy of a data subject due to use and provision of personal information thereof; Measures that are in place to
      protect personal information to be used and provided.</p>

    <p>&nbsp;</p>

    <ul>
      <li>Whether there is any relevance to the purposes for which the personal information was initially collected;
      </li>
      <li>Whether there is any predictability of additional use or provision of personal information considering
        circumstances under which the said personal information is collected or the processing practices thereof;
      </li>
      <li>Whether the interests of a data subject are unfairly infringed on;</li>
      <li>Whether necessary measures to secure safety such as pseudonymization or encryption have been taken.</li>
    </ul>

    <p>&nbsp;</p>

    <p><strong>Article 2 Period for Processing and Retaining Personal Information</strong></p>

    <ol>
      <li>The Company may retain and use personal information until the purposes of processing the said personal
        information are fulfilled within the period for retaining and using personal information: prescribed by
        statutes; or for which consent is obtained from a data subject at the time of personal information collection.
        Such data shall be destroyed without delay when the aforementioned purposes of processing are attained.
      </li>
    </ol>

    <ol>
      <li>General information concerning medical professionals including doctors: Until a service agreement regarding
        product purchase expires;
      </li>
      <li>Data concerning user complaints and reports on adverse events and other safety and quality issues: Three years
        from the date of reporting;
      </li>
      <li>General data on job candidates: Three years from the date of the submission of a job application document;
      </li>
      <li>General data regarding subcontractors and suppliers: Until the execution of services regarding a contract,
        project or proposal finishes;
      </li>
      <li>Sending of newsletters: Three year from the date of the subscription to newsletters.</li>
    </ol>

    <p>&nbsp;</p>

    <ol>
      <li>In the event that personal information protection is deemed necessary pursuant to relevant statutes including
        the Commercial Act and the Protection of Communications Secrets Act, the Company may share personal information
        within the period for retaining and using personal information prescribed by the aforesaid statutes.
      </li>
    </ol>

    <ul>
      <li>All important documents relating to the Company's business</li>
    </ul>

    <p>Legal grounds for retention: The Commercial Act</p>

    <p>Retention period: Ten years</p>

    <ul>
      <li>Website browsing history</li>
    </ul>

    <p>Legal grounds for retention: The Protection of Communications Secrets Act</p>

    <p>Retention period: Three months</p>

    <p>&nbsp;</p>

    <p><strong>Article 3 Provision of Personal Information to Third Parties</strong></p>

    <ol>
      <li>The Company may in principle process personal information within the scope prescribed by Article 1 of the
        Policy. Without the consent of a data subject, the Company shall not process the personal information of the
        said data subject beyond the aforementioned scope, nor provide such data to any third party, except in any of
        the following circumstances.
      </li>
    </ol>

    <ol>
      <li>Where prior consent of a data subject is obtained for provision and disclosure of information to third
        parties;
      </li>
      <li>Where provision of personal information is deemed mandatory or allowable pursuant to statutes.</li>
    </ol>

    <p>&nbsp;</p>

    <ol>
      <li>&nbsp;[Researchers who belong to clinical testing organizations]</li>
    </ol>

    <table cellspacing="0" style="border-collapse:collapse">
      <tbody>
      <tr>
        <td
          style="background-color:#f2f2f2; border-bottom:1px solid black; border-left:1px solid black; border-right:1px solid black; border-top:1px solid black; vertical-align:top; width:140px">
          <p><strong>Recipient</strong></p>
        </td>
        <td
          style="background-color:#f2f2f2; border-bottom:1px solid black; border-left:none; border-right:1px solid black; border-top:1px solid black; vertical-align:top; width:132px">
          <p><strong>Purpose of provision</strong></p>
        </td>
        <td
          style="background-color:#f2f2f2; border-bottom:1px solid black; border-left:none; border-right:1px solid black; border-top:1px solid black; vertical-align:top; width:133px">
          <p><strong>Particulars to be provided</strong></p>
        </td>
        <td
          style="background-color:#f2f2f2; border-bottom:1px solid black; border-left:none; border-right:1px solid black; border-top:1px solid black; vertical-align:top; width:136px">
          <p><strong>Period for retention and use</strong></p>
        </td>
      </tr>
      <tr>
        <td
          style="border-bottom:1px solid black; border-left:1px solid black; border-right:1px solid black; border-top:none; width:140px">
          <p>Ministry of Food and Drug Safety</p>
        </td>
        <td
          style="border-bottom:1px solid black; border-left:none; border-right:1px solid black; border-top:none; width:132px">
          <p>To submit clinical testing plans on medical devices; and fulfill the duty to report according to relevant
            statutes including the Medical Devices Act</p>
        </td>
        <td
          style="border-bottom:1px solid black; border-left:none; border-right:1px solid black; border-top:none; width:133px">
          <p>Names, organizations, majors, positions and contact points of persons in charge of testing, experimenters
            and co-researchers &nbsp;&nbsp;</p>
        </td>
        <td
          style="border-bottom:1px solid black; border-left:none; border-right:1px solid black; border-top:none; width:136px">
          <p>Until the purposes herein stated are attained</p>
        </td>
      </tr>
      </tbody>
    </table>

    <p>&nbsp;</p>

    <p><strong>Article 4 Outsourcing of Personal Information Processing</strong></p>

    <ol>
      <li>The Company outsources personal information processing to a third party as below to facilitate the execution
        of processing.
      </li>
    </ol>

    <table cellspacing="0" style="border-collapse:collapse; width:529px">
      <tbody>
      <tr>
        <td
          style="background-color:#f2f2f2; border-bottom:1px solid black; border-left:1px solid black; border-right:1px solid black; border-top:1px solid black; height:25px; width:151px">
          <p><strong>Outsourcee</strong></p>
        </td>
        <td
          style="background-color:#f2f2f2; border-bottom:1px solid black; border-left:none; border-right:1px solid black; border-top:1px solid black; height:25px; width:378px">
          <p><strong>Particulars of outsourced work</strong></p>
        </td>
      </tr>
      <tr>
        <td
          style="border-bottom:1px solid black; border-left:1px solid black; border-right:1px solid black; border-top:none; height:1px; vertical-align:top; width:151px">
          <p>MIDAS Information Technology Co., Ltd.</p>
        </td>
        <td
          style="border-bottom:1px solid black; border-left:none; border-right:1px solid black; border-top:none; height:1px; vertical-align:top; width:378px">
          <p>To manage a recruitment webpage and employment procedures</p>

          <p>To run a computing system and handle relevant complaints</p>
        </td>
      </tr>
      </tbody>
    </table>

    <ol>
      <li>In accordance with Article 25 of the Personal Information Protection Act, the Company specifies particulars in
        a contract in the event of the execution of an outsourcing agreement: prevention of personal information
        processing for other purposes than the initial purpose of the outsourcing; technical and managerial safeguards
        of personal information; restriction of re-outsourcing; management and supervision of the outsourcee; and
        matters on compensation and responsibilities.
      </li>
      <li>The Company also supervises how the outsourcee processes such personal information safely by inspecting the
        status of processing.
      </li>
    </ol>

    <p>&nbsp;</p>

    <p>The Company shall be obligated to make public any modification or update to particulars of the outsourcee and
      outsourced work via the Policy without delay.</p>

    <p>&nbsp;</p>

    <p><strong>Article 6 Particulars of Personal Information to be Processed and Processing Methods thereof</strong></p>

    <ol>
      <li>The Company processes the following particulars of personal information.</li>
    </ol>

    <ol>
      <li>Subscribers to website newsletters: E-mail address</li>
      <li>General information concerning medical professionals including doctors
        <ul>
          <li>Necessary information to collect: Name, postal address, date of birth, gender, phone number, e-mail
            address, organization, qualifications, department, specialty, academic background, work experience,
            publications, theses, etc.
          </li>
          <li>Optional information to collect: License number, etc.</li>
        </ul>
      </li>
    </ol>

    <ol>
      <li>Data concerning user complaints and reports on adverse events and other safety and quality issues
        <ul>
          <li>Necessary information to collect: Name, date of birth or age, phone number, postal address, e-mail
            address, etc.
          </li>
          <li>Optional information to collect: Health information (area to be treated, field of inquiry, product
            information, particulars of safety-related information, duration, etc.), in-charge hospital and doctor
            (field of specialty, employment status and organization), etc.
          </li>
        </ul>
      </li>
      <li>General data on job candidates
        <ul>
          <li>Necessary information to collect: Name, postal address, nationality, phone number, eligibility for
            compensation and benefits for veterans and persons with disabilities, academic background and performance,
            status of military service, work experience, intellectual property, theses, publications, research
            performance, overseas experience, professional experience, social activities, community service, language
            proficiency and other qualifications, cover letter, etc.
          </li>
          <li>Optional information to collect: ID picture, date of birth, academic background, certificates, work
            experience, language proficiency, personal information described in a resume and other information that a
            job applicant consents to the collection thereof
          </li>
        </ul>
      </li>
      <li>General data regarding subcontractors and suppliers
        <ul>
          <li>Necessary information to collect: Name, company name, business registration number, bank account number
            and postal address
          </li>
          <li>Optional information to collect: Phone number, e-mail address, fax number, login ID, password, etc.</li>
        </ul>
      </li>
    </ol>

    <ol>
      <li>The Company collects personal information prescribed by Subparagraph 1 by any of the following methods or
        processes:
      </li>
    </ol>

    <ul>
      <li>Where clients create an account to use a product</li>
      <li>Where feedback or a complaint on a product is submitted</li>
      <li>Where job candidates apply for the Company and executives or employees work at the Company</li>
      <li>Where an agreement with a subcontractor or supplier is executed</li>
    </ul>

    <p>&nbsp;</p>

    <p><strong>Article 7 Destruction of Personal Information</strong></p>

    <p>The Company shall destroy personal information without delay when the personal information becomes unnecessary
      owing to the expiry of the retention period, attainment of the purpose of processing the personal information,
      etc. The procedures, deadline and methods of destroying personal data are stated as follows.</p>

    <ol>
      <li>Procedures of destruction</li>
    </ol>

    <p>Personal information is transmitted to an independent database (or separate document in the case of personal
      information written on paper) when the retention period expires or the purposes of retention are attained. Such
      personal information is destroyed without delay after being retained during a particular period prescribed by
      internal guidelines or relevant statutes. Once personal information is transmitted to the aforesaid database, the
      personal information shall not be used for any purpose other than the purpose for which the said personal
      information is used.</p>

    <ol>
      <li>Methods of destruction</li>
    </ol>

    <p>Paper documents containing personal information shall be shredded or incinerated. Personal information in
      electronic files shall be permanently erased not to restore data.</p>

    <p>&nbsp;</p>

    <p><strong>Article 8 Measures to Ensure Safety of Personally Identifiable Information</strong></p>

    <p>The Company has technical, managerial and physical safeguards in place to ensure the safety of personal
      information in accordance with Article 29 of the Personal Information Protection Act as follows.</p>

    <ol>
      <li>Managerial measures</li>
    </ol>

    <p>Establishment and implementation of internal management plans, regular employee training programs, etc.</p>

    <ol>
      <li>Technical measures</li>
    </ol>

    <p>Access authority management for personal data processing systems, installation of access control software,
      encryption of personally identifiable information, installation of security programs, etc.</p>

    <ol>
      <li>Physical measures</li>
    </ol>

    <p>Access control to computer rooms, archives, etc.</p>

    <p>&nbsp;</p>

    <p><strong>Article 9 Privacy Officers</strong></p>

    <ol>
      <li>The Company has a privacy officer on duty as follows to ensure comprehensive responsibility for personal
        information processing; and handle data subjects’ grievances and remedial compensation relevant to personal
        information.
      </li>
    </ol>

    <p>&nbsp;</p>

    <p>Privacy officer in charge</p>

    <p>-&nbsp;&nbsp;&nbsp; Name: Jae-hyung Park</p>

    <p>-&nbsp;&nbsp;&nbsp; Department and position: HR/CHO</p>

    <p>-&nbsp;&nbsp;&nbsp; Contact information: 02-515-6646 / apply@vuno.co</p>

    <p>&nbsp;</p>

    <ol>
      <li>Data subjects may make all types of inquiries arising out of using any of the Company’s services (or projects)
        on personal information and handling of grievances and remedial compensation to the privacy officer or the
        department in charge. The Company answers and handles inquiries from data subjects without delay.
      </li>
    </ol>

    <p>&nbsp;</p>

    <p><strong>Article 10 Matters on Installation, Operation and Rejection of Automatic Collection Equipment for
      Personal Information</strong></p>

    <p>The Company uses cookies to store and find user information. A cookie is a small text file containing information
      that a server used to run a website sends to a user browser, which is stored on a user's computer hard drive. The
      Company uses cookies for the following purposes.</p>

    <p>&nbsp;</p>

    <ol>
      <li>Purpose of using cookies: To ensure improvements in the Company's services by executing web analytics
        regarding user connection frequency and visiting times of members and non-members; and by keeping track of user
        preferences, fields of interest, digital footprints, access to online materials and visiting patterns.
      </li>
      <li>Type of cookies</li>
    </ol>

    <table cellspacing="0" style="border-collapse:collapse">
      <tbody>
      <tr>
        <td
          style="background-color:#f2f2f2; border-bottom:1px solid black; border-left:1px solid black; border-right:1px solid black; border-top:1px solid black; vertical-align:top; width:184px">
          <p><strong>Name</strong></p>
        </td>
        <td
          style="background-color:#f2f2f2; border-bottom:1px solid black; border-left:none; border-right:1px solid black; border-top:1px solid black; vertical-align:top; width:184px">
          <p><strong>Function</strong></p>
        </td>
        <td
          style="background-color:#f2f2f2; border-bottom:1px solid black; border-left:none; border-right:1px solid black; border-top:1px solid black; vertical-align:top; width:174px">
          <p><strong>Expiry time</strong></p>
        </td>
      </tr>
      <tr>
        <td
          style="border-bottom:1px solid black; border-left:1px solid black; border-right:1px solid black; border-top:none; vertical-align:top; width:184px">
          <p>Ccn</p>
        </td>
        <td
          style="border-bottom:1px solid black; border-left:none; border-right:1px solid black; border-top:none; vertical-align:top; width:184px">
          <p>Prevention of CSRF (Cross-Site Request Forgery)</p>
        </td>
        <td
          style="border-bottom:1px solid black; border-left:none; border-right:1px solid black; border-top:none; vertical-align:top; width:174px">
          <p>Session</p>
        </td>
      </tr>
      <tr>
        <td
          style="border-bottom:1px solid black; border-left:1px solid black; border-right:1px solid black; border-top:none; vertical-align:top; width:184px">
          <p>Ci_session</p>
        </td>
        <td
          style="border-bottom:1px solid black; border-left:none; border-right:1px solid black; border-top:none; vertical-align:top; width:184px">
          <p>Identification of users</p>
        </td>
        <td
          style="border-bottom:1px solid black; border-left:none; border-right:1px solid black; border-top:none; vertical-align:top; width:174px">
          <p>Session</p>
        </td>
      </tr>
      <tr>
        <td
          style="border-bottom:1px solid black; border-left:1px solid black; border-right:1px solid black; border-top:none; vertical-align:top; width:184px">
          <p>_ga</p>
        </td>
        <td
          style="border-bottom:1px solid black; border-left:none; border-right:1px solid black; border-top:none; vertical-align:top; width:184px">
          <p>Transmission of data regarding user devices and website behaviors</p>
        </td>
        <td
          style="border-bottom:1px solid black; border-left:none; border-right:1px solid black; border-top:none; vertical-align:top; width:174px">
          <p>One year</p>
        </td>
      </tr>
      </tbody>
    </table>

    <p>&nbsp;</p>

    <ol>
      <li>Cookie rejection: Data subjects have the right to decide upon cookie installation. Cookie settings on a web
        browser enable data subjects to allow all cookies; be prompted with a window asking about consent for storing
        cookies; or block all cookies.
      </li>
      <li>Procedures of setting cookies (Internet Explorer)</li>
    </ol>

    <p>- Tools in the browser toolbar &gt; Internet Options &gt; Privacy tab. Provided, if cookie installation is
      rejected, it may be difficult to use certain services.</p>

    <p>&nbsp;</p>

    <p><strong>Article 11 Installation and Operation of Visual Data Processing Equipment</strong></p>

    <p>The Company runs visual data processing equipment and manages the operation thereof.</p>

    <ol>
      <li>Purpose of installing visual data processing equipment: To provide on-premises security, prevent fire and
        crime and provide cooperation in case of crime investigation
      </li>
      <li>The number of equipment installed, locations and scope of recording thereof: One device for each entrance on
        each floor, where the Company's headquarters offices are situated, keeps record of each lobby area and corridor
        (two for the fifth and sixth floors each; one for the eighth and tenth floors each).
      </li>
      <li>Person and department in charge and with access to visual data: Jin-young Lee, HR/GA Part</li>
      <li>Recording time, retention period, storage place and management method of visual data</li>
    </ol>

    <ul>
      <li>Recording hours: 24 hours non-stop recording</li>
      <li>Retention period: 90 days from the date of recording</li>
      <li>Storage place and management method: HR/GA part in charge of management that is located on the eighth floor
        destroys stored personal data upon the expiration of retention period.
      </li>
    </ul>

    <ol>
      <li>Method and place of visual data confirmation: Contact the person in charge measures to deal with the request
        of data subjects for inspection of visual data: Request for inspection of visual data or request for checking
        the existence of data may be made to the operator of the visual data processing equipment. In such a case, an
        inspection of visual data may be allowed only if the person requesting it has been recorded as the subject of
        such recording, or if it is deemed obviously necessary in the physical safety and property interest thereof.
      </li>
      <li>Technical, administrative and physical measures to protect visual data:</li>
    </ol>

    <ul>
      <li>Establishment and implementation of internal management plans, access control and restriction of access
        authority, introduction of storage and transmission technology to secure visual data, storage of processed data,
        forgery prevention, installation of storage space and security locks, etc.&nbsp;&nbsp;
      </li>
    </ul>

    <ol>
      <li>Other matters concerning installation, operation and management of visual data processing equipment: The
        Company shall not operate any visual data processing device outside the scope reasonably related to the purposes
        for which visual data was initially collected according to relevant statutes; nor so as to look into the places
        which is likely to noticeably threaten individual privacy, such as a bathroom, restroom, sauna, and dressing
        room used by multiple unspecified persons. The Company shall not arbitrarily handle the visual data processing
        devices for other purposes than the initial one; nor use sound recording functions.
      </li>
    </ol>

    <p>&nbsp;</p>

    <p><strong>Article 12 Matters on Third Party Links</strong></p>

    <p>The Company's website may include links to third party websites, plug-ins and application programs. A third party
      may collect or use a data subject's personal information in the event of a click on a third party link or a
      connection thereto. The Company does not have any control of third party websites, plug-ins and application
      programs, and thus shall not be responsible or liable for any third party's privacy policy.</p>

    <p>&nbsp;</p>

    <p><strong>Article 13 Modification of Privacy Policy</strong></p>

    <ol>
      <li>The Policy takes effect on the date of execution. In the event that content is added, deleted or corrected in
        the Policy according to changes in relevant statutes and policies, the Company shall make public modified
        details via a notice section at least seven days before the new updated version of the privacy policy comes into
        effect.
      </li>
      <li>The Policy was modified and updated as follows.</li>
    </ol>

    <ul>
      <li>Modified on January 1 2020; March 10 2020; and November 9 2020.</li>
    </ul>

    <p>&nbsp;</p>
  </div>
</template>

<script>
export default {
  name: 'PrivateEn',
}
</script>
