// 메소드 네임 가이드
// api_${name}() {}

import env from "../plugins/process.env"
import axios from "../plugins/axios"

export default {
  // 이벤튼 정보 조회
  api_getStreamInfo() {
    const it = this

    return axios({
      method: 'GET',
      baseURL: env.API_BASE_URL,
      url: `/api/v1/event/${it.$route.params.uid}/show_event_whith_modules`,
    }).then( result => {
      const state = result.data.code
      const info = result.data.result
      const tabs = info.modules || []
      const types = it.stream_tabDefaultActiveType

      // 페이지에서 강제로 나가게 해야 할 경우
      // ( 응답값 비정상 || 진행중이 아님 || 사전미등록 사용자 )
      if (state !== 200 || info.status !== 1 || info.is_attend !== 1) {
        // UX 를 위해 왜 튕겨나가는지 비동게 안내창 띄운다
        setTimeout(() => {
          const lang = it.gc_lang
          const texts = it.gc_langText.common.alert.text
          const text = info.status !== 1 ? texts.noStreamingProgress[lang] :
            info.is_attend !== 1 ? texts.needAttend[lang] : texts.error[lang]

          it.gm_alertOpen({ text })
        })
        throw new Error('reject')
      }

      // 정상적인 경우
      if (state === 200) {
        const i = tabs.findIndex( item => -1 < types.indexOf(item.type) )
        const question =
          tabs.find( item => item.type === 'questiontospeaker') || { id: -1 }
        const speaker =
          tabs.find( item => item.type === 'speaker') || { id: -1 }

        it.info = info
        it.setTab(i)
        it.api_getChatList()
        it.api_getQuestion(info.active_subtitle_id)
        it.question.module_id = question.id
        it.question.speaker_module_id = speaker.id

        let method = null

        // 질문 리스트를 가지고 와야 할 경우
        if (it.gc_user.admin_level === 40) {
          method = it.api_getStreamQuestionListFilter()
        } else if (it.gc_user.admin_level < 40) {
          method = it.api_getStreamQuestionList()
        }

        if (method) {
          method.finally( () => it.$store.state.pageLoaded = true )
        } else {
          it.$store.state.pageLoaded = true
        }

        it.io_onDuplicateConnected()
      }
    }).catch( () => {
      it.forceRouting = true
      it.$router.push({ name: 'Home' })
    })
  },

  // 질문 리스트 가지고 오기
  api_getStreamQuestionList() {
    if (this.question.module_id === null) { return }

    const it = this
    const params = {
      module_id: it.question.module_id
    }

    return axios({
      method: 'GET',
      baseURL: env.API_BASE_URL,
      url: `/api/v1/question/get_question_list`,
      params
    }).then( result => {
      const state = result.data.code
      const list = result.data.result

      // 정상적인 경우
      if (state === 200) {
        it.question.list = list
      }
    })
  },

  // 질문 리스트 필터링 된 것만 가지고 오기
  api_getStreamQuestionListFilter() {
    if (this.question.module_id === null) { return }

    const it = this
    const params = {
      module_id: it.question.module_id
    }

    return axios({
      method: 'GET',
      baseURL: env.API_BASE_URL,
      url: `/api/v1/question/get_filtered_question_list`,
      params
    }).then( result => {
      const state = result.data.code
      const list = result.data.result

      // 정상적인 경우
      if (state === 200) {
        it.question.list = list
      }
    })
  },

  // 질문 정보 호출
  api_getQuestion(question_id) {
    const it = this

    return axios({
      method: 'GET',
      baseURL: env.API_BASE_URL,
      url: `/api/v1/question/${question_id}/show_question`,
    }).then( result => {
      const state = result.data.code
      const question = result.data.result

      // 정상적인 경우
      if (state === 200) {
        it.question.active = question.is_use_subtitle ? question.contents : ''
      }
    })
  },

  // 질문 필터링 온오프
  api_questionFilterToggle(question_id) {
    return axios({
      method: 'POST',
      baseURL: env.API_BASE_URL,
      url: `/api/v1/question/${question_id}/filter_toggle`,
    })
  },

  // 질문 채크박스 온오프
  api_questionCheckedToggle(question_id) {
    return axios({
      method: 'POST',
      baseURL: env.API_BASE_URL,
      url: `/api/v1/question/${question_id}/checked_toggle`,
    })
  },

  // 질문 활성화 온오프
  api_questionActiveToggle(question_id) {
    return axios({
      method: 'POST',
      baseURL: env.API_BASE_URL,
      url: `/api/v1/question/${question_id}/change_is_active_subtitle`,
    })
  },

  // 발표자 리스트 요청
  api_getQuestionSpeaker() {
    const it = this
    const params = {
      module_id: it.speaker_module_id
    }

    it.requestingSpeaker = true

    return axios({
      method: 'GET',
      baseURL: env.API_BASE_URL,
      url: `/api/v1/speaker/get_speaker_list`,
      params
    }).then( result => {
      const state = result.data.code
      const list = result.data.result

      if (state !== 200) { throw state }

      it.listOrigin = list
      it.requestingSpeaker = false
    }).catch(error => {
      it._api_error(`code: ${error}\nat: api_getQuestionSpeaker`)
    }).finally( () => {
      // 여전히 발표자 리스트 요청중이라면 애러로 관주하고 팝업을 닫는다
      if (it.requestingSpeaker) {
        it.show = false
      }
    })
  },

  // 질문 등록
  api_submitQuestion() {
    const it = this
    const params = {
      module_id: it.module_id,
      speaker_id: it.form.presenter,
      contents: it.form.content
    }

    it.requesting = true

    return axios({
      method: 'POST',
      baseURL: env.API_BASE_URL,
      url: `/api/v1/question`,
      params
    }).then( result => {
      const state = result.data.code
      const lang = this.gc_lang
      const text = this.gc_langText.stream.text.registration[lang]

      it.requesting = false

      if (state !== 200) { throw state }

      it.gm_alertOpen({
        text,
        callback: () => it.show = false
      })
    }).catch(error => {
      it._api_error(`code: ${error}\nat: api_submitQuestion`)
    })
  },

  // 질문 수정
  api_submitQuestionEdit() {
    const it = this
    const params = {
      contents: this.form.content
    }

    it.requesting = true

    return axios({
      method: 'PUT',
      baseURL: env.API_BASE_URL,
      url: `/api/v1/question/${it.question.id}`,
      params
    }).then( result => {
      const state = result.data.code

      // 정상적인 경우
      if (state !== 200) { throw state }

      const lang = this.gc_lang
      const text = this.gc_langText.stream.text.updatedQuestion[lang]

      it.gm_alertOpen({ text })
    }).catch(error => {
      it._api_error(`code: ${error}\nat: api_submitQuestionEdit`)
    })
      .finally( () => {
        it.requesting = false
        it.show = false
      })
  },

  // 채팅 리스트 가지고 오기
  api_getChatList() {
    const it = this
    const params = {
      event_id: it.info.id
    }

    return axios({
      method: 'GET',
      baseURL: env.API_BASE_URL,
      url: `/api/v1/chat`,
      params
    }).then( result => {
      const state = result.data.code
      const messages = result.data.result

      // 정상적인 경우
      if (state === 200) {
        it.chatPopup.messages = messages
      } else {
        it.chatPopup.messages = []
      }
    }).catch( error => {
      console.error('API Chat: ', error)
      it.chatPopup.messages = it.chatPopup.messages ? it.chatPopup.messages : []
    })
  },

  // 채팅 메세지 전송
  api_sendChatMessage() {
    const it = this
    const params = {
      event_id: it.event_id,
      content: it.form.text
    }

    // 전송할 메세지가 없을 경우 리턴
    if (!it.form.text) { return }

    it.form.requesting = true
    it.$emit('pushLocalMessage', params.content)

    return axios({
      method: 'POST',
      baseURL: env.API_BASE_URL,
      url: `/api/v1/chat`,
      params
    }).then( result => {
      const state = result.data.code

      // 정상적인 경우에만
      if (state === 200) {
        it.form.text = ''
      }
    }).catch(error => {
      it._api_error(`code: ${error}\nat: api_sendChatMessage`)
    }).finally( () => {
      it.form.requesting = false
      it.needFocusToInput = true
    })
  },

  // 스트리밍 입/퇴장 기록
  api_streamUserIO(stream_id = 0, type = '') {
    // 전송할 메세지가 없을 경우 리턴
    if (!stream_id || !type) { return }

    const params = { type }

    return axios({
      method: 'GET',
      baseURL: env.API_BASE_URL,
      url: `/api/v1/event/${stream_id}/check_event`,
      params
    })
  },
}