// 메소드 네임 가이드
// gm_${name}() {}

// import store from "../store"

export default {
  // 페이지 언어 변경
  gm_changeLang(lang) {
    this.$store.state.lang = lang
    localStorage.setItem('lang', lang)
  },

  // 인풋창에서 앤터로 서브밋 버튼 호출...
  gm_submitEnter() {
    // 사실 뭔가 개판이다... 이슈가 있지만 아무도 모르니 이대로 간다....
    const el = this.$refs.submit.$el

    el.focus()
    setTimeout(() => el.click())
  },

  // 알림창 호출
  gm_alertOpen(options = {}) {
    const alert = this.$store.state.alert

    alert.title = options.title || this.gc_langText.common.alert.title[this.gc_lang]
    alert.text = options.text || ''
    alert.confirm = !!options.confirm
    alert.callback = typeof(options.callback) === 'function' ? options.callback : null
    alert.width = options.width || 320
    alert.fullscreen = !!options.fullscreen
    alert.confirmAgreeText = options.confirmAgreeText || this.gc_langText.common.alert.confirmAgreeText[this.gc_lang]
    alert.confirmDisagreeText = options.confirmDisagreeText || this.gc_langText.common.alert.confirmDisagreeText[this.gc_lang]
    alert.closeText = options.closeText || this.gc_langText.common.alert.closeText[this.gc_lang]

    alert.show = true
  },

  // 알림창 닫기
  gm_alertClose(confirm = false) {
    const alert = this.$store.state.alert
    const callback = alert.callback

    alert.show = false
    confirm && alert.callback && callback(confirm)
  },

  // 메인 네비게이션 스트리밍 이동 가능 여부 실행 로직
  gm_clickNavStream() {
    let mainStream = this.$store.state.mainStream || []

    // 이미 해당 페이지에 있을 경우 리턴
    if (this.app_isActiveLiveNav) { return }
    // 라이브 스트리밍 리스트가 2개 이상일 경우 (선택지 팝업 띄우기)
    else if (mainStream.length >= 2) {
      this.$store.state.streamListShow = true
      return
    }
    // 라이브 스트리밍 리스트가 비었을 경우
    // (서버에서 전달 받을 때 없을 경우 빈 배열이 기본이지만 예외를 고려하여 임의로 넣은 로직)
    else if (!mainStream.length) {
      mainStream = {}
    }
    // 라이브 스트리밍 리스트가 1개일 경우
    else if (mainStream.length === 1) {
      mainStream = mainStream[0]
    }

    this.gm_goToStream( mainStream.id )
  },

  gm_goToStream(uid) {
    const mainStream = this.$store.state.mainStream
      .find(event => event.id === uid) || {}
    const isLive = mainStream.status === 1
    const isAttend = mainStream.is_attend === 1
    const isUser = !!this.$store.state.user
    const lang = this.gc_lang
    const texts = this.gc_langText

    // 해당 스트리밍으로 이동이 가능한 경우
    if (isLive && isAttend && isUser) {
      // 리스트 팝업 초기화
      this.$store.state.streamListShow = false

      this.$router.push({
        name: 'Stream',
        params: { uid }
      })
    }
    // 진행중인 스트리밍이 없을 경우
    else if (uid === undefined || !isLive) {
      this.gm_alertOpen({
        text: texts.common.alert.text.noStreamingProgress[lang]
      })
    }
    // 미로그인 사용자
    else if (!isUser) {
      this.gm_alertOpen({
        text: texts.common.alert.text.needLogIn[lang]
      })
    }
    // 사전등록을 하지 않은 경우
    else if (uid && !isAttend) {
      this.gm_alertOpen({
        text: texts.common.alert.text.needAttend[lang]
      })
    }
  },

  // SSO 링크 이동
  gm_goToSso() {
    // 사내직원이 아닐경우 리턴
    if (this.gc_user?.type !== 2) { return }

    const base = 'https://vuno.hunet.co.kr/Login/__sigUpLOGIN?USERID='
    const email = this.gc_user?.email || ''
    const id = email.replace(/@.+$/, '')
    const uid = btoa(id)
    const url = `${base}${uid}`

    return window.open(url, '_blank')
  }
}