export default {
  // 사용자 개인정보 변경 페이지 링크
  app_accountLink() {
    const user = this.$store.state.user || {}
    const uid = user.id || '123'
    const name = 'Account'
    const params = { uid }

    return { name, params }
  },

  // 바 구성 컴포넌트 중 라우터 name 에 따라 보여야 하는지 여부
  app_show() {
    const name = this.$route.name

    // 보이면 안되는 라우터 네임 리스트
    const reject = [
      'Login',
      'Join',
      'Stream',
      'Inquiry',
      'Alteration',
      'Account'
    ]

    return !reject.some( _name => name === _name)
  },

  // 라이브 네비게이션 버튼 활성화 여부
  app_isActiveLiveNav() {
    const isStreamRouter = this.$route.name === 'Stream'
    const routerQueryId = Number( this.$route.query.id )
    const isValidId = this.gc_mainStream.some( event => {
      return event.id === routerQueryId
    })

    return isStreamRouter && isValidId
  },

  // 라이브 네비게이션 버튼 뱃지 활성화 여부
  app_isActiveLiveNavBadge() {
    return this.gc_mainStream.some( event => event.status === 1 )
  }
}