import Vue from "vue"

import methodsAPI from "./methodsAPI"
import methodsCommon from "./methodsCommon"
import methodsForm from "./methodsForm"
import methodsHome from "./methodsHome"
import methodsAPIJoin from "./methodsAPIJoin"
import methodsIO from "./methodsIO"

import computedCommon from "./computedCommon"
import computedApp from "./computedApp"
import computedForm from "./computedForm"
import computedStream from "./computedStream"

const mixin = {
  methods: {
    ...methodsAPI,
    ...methodsCommon,
    ...methodsForm,
    ...methodsHome,
    ...methodsAPIJoin,
    ...methodsIO
  },

  computed: {
    ...computedCommon,
    ...computedApp,
    ...computedForm,
    ...computedStream
  }
}

Vue.mixin( mixin )

export default mixin